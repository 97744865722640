import {
	createAsyncThunk,
	createEntityAdapter,
	createSlice,
} from '@reduxjs/toolkit';
import { logout } from './userSlice';
import atsApi from '../../api/atsApi';

export const createCategory = createAsyncThunk(
	'category/add_new',
	async (data, { rejectWithValue }) => {
		try {
			const res = await atsApi.post('/categories', data);
			return res.data;
		} catch (err) {
			return rejectWithValue(err.response.data);
		}
	}
);

export const getAllCategories = createAsyncThunk(
	'category/get_all',
	async (data, { rejectWithValue }) => {
		try {
			const res = await atsApi.get('/categories');
			return res.data;
		} catch (err) {
			return rejectWithValue(err.response.data);
		}
	}
);

export const deleteCategory = createAsyncThunk(
	'category/delete_category',
	async (data, { rejectWithValue }) => {
		try {
			const res = await atsApi.delete(`/categories/${data}`);
			return res.data;
		} catch (err) {
			return rejectWithValue(err.response.data);
		}
	}
);

export const categoryAdapter = createEntityAdapter();
const initialState = categoryAdapter.getInitialState({
	loading: false,
	isCreateCategory: false,
	newCategory: '',
	allCategories: [],
	success: null,
	errors: null,
});

export const categorySlice = createSlice({
	name: 'category',
	initialState,
	reducers: {
		setIsCreateCategory: (state, action) => {
			state.isCreateCategory = action.payload;
		},
		setNewCategory: (state, action) => {
			state.newCategory = action.payload;
		},
		setAllCategories: (state, action) => {
			state.allCategories = action.payload;
		},
	},
	extraReducers: (builder) => {
		builder
			.addCase(createCategory.pending, (state) => {
				state.loading = true;
				state.errors = null;
			})
			.addCase(createCategory.fulfilled, (state, action) => {
				state.loading = false;
				state.success = action.payload.success;
				state.allCategories = action.payload.allCategories;
				state.isCreateCategory = false;
				state.newCategory = '';
			})
			.addCase(createCategory.rejected, (state, action) => {
				state.loading = false;
				state.errors = action.payload;
			})
			.addCase(getAllCategories.pending, (state) => {
				state.loading = true;
				state.errors = null;
			})
			.addCase(getAllCategories.fulfilled, (state, action) => {
				state.loading = false;
				state.allCategories = action.payload;
			})
			.addCase(getAllCategories.rejected, (state, action) => {
				state.loading = false;
				state.errors = action.payload;
			})
			.addCase(deleteCategory.pending, (state) => {
				state.loading = true;
				state.errors = null;
			})
			.addCase(deleteCategory.fulfilled, (state, action) => {
				state.loading = false;
				state.success = action.payload.success;
				state.allCategories = action.payload.allCategories;
			})
			.addCase(deleteCategory.rejected, (state, action) => {
				state.loading = false;
				state.errors = action.payload;
			})
			.addCase(logout, (state) => {
				state.loading = false;
				state.isCreateCategory = false;
				state.newCategory = '';
				state.allCategories = [];
				state.success = null;
				state.errors = null;
				categoryAdapter.removeAll(state);
			});
	},
});

export const { setIsCreateCategory, setNewCategory, setAllCategories } =
	categorySlice.actions;

export default categorySlice.reducer;
