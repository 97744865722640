import {
	Button,
	Checkbox,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	FormControlLabel,
} from '@mui/material';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import TextInput from '../TextInput';
import {
	setDialogOpen,
	setFirstName,
	setLastName,
	setEmail,
	addUser,
	clearErrors,
} from '../../redux/slices/userSlice';

const AddUserDialog = () => {
	const { dialogOpen, firstName, lastName, email, errors } = useSelector(
		(state) => state.user
	);
	const [checked, setChecked] = useState(false);
	const dispatch = useDispatch();

	const handleClose = () => {
		dispatch(setDialogOpen(false));
		dispatch(setFirstName(''));
		dispatch(setLastName(''));
		dispatch(setEmail(''));
	};

	const handleFocus = () => {
		dispatch(clearErrors());
	};

	const handleChange = (input, value) => {
		const actionMap = {
			confirm: setChecked,
			first: setFirstName,
			last: setLastName,
			email: setEmail,
		};

		const action = actionMap[input];

		if (input === 'confirm') {
			action && action(value);
		} else {
			action && dispatch(action(value));
		}
	};

	const handleAddUser = (e) => {
		e.preventDefault();
		const data = {
			firstName,
			lastName,
			email,
			username: email,
			send: checked,
		};

		dispatch(addUser(data));
	};

	return (
		<Dialog
			open={dialogOpen}
			PaperProps={{ component: 'form', onSubmit: handleAddUser }}
			maxWidth='xs'
			fullWidth
		>
			<DialogTitle>Add User</DialogTitle>
			<DialogContent style={{ display: 'flex', flexDirection: 'column' }}>
				<TextInput
					label='First Name'
					size='small'
					margin='dense'
					value={firstName}
					onFocus={handleFocus}
					onChange={(e) => handleChange('first', e.target.value)}
					error={errors?.firstName}
				/>
				<TextInput
					label='Last Name'
					size='small'
					margin='dense'
					value={lastName}
					onFocus={handleFocus}
					onChange={(e) => handleChange('last', e.target.value)}
					error={errors?.lastName}
				/>
				<TextInput
					type='email'
					label='Email'
					size='small'
					margin='dense'
					value={email}
					onFocus={handleFocus}
					onChange={(e) => handleChange('email', e.target.value)}
					error={errors?.email}
				/>
				<FormControlLabel
					control={
						<Checkbox
							checked={checked}
							color='success'
							onChange={(e) => handleChange('confirm', e.target.checked)}
						/>
					}
					label='Send Email'
					labelPlacement='start'
				/>
			</DialogContent>
			<DialogActions>
				<Button onClick={handleClose}>Cancel</Button>
				<Button type='submit'>Submit</Button>
			</DialogActions>
		</Dialog>
	);
};

export default AddUserDialog;
