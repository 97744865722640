import {
	createAsyncThunk,
	createEntityAdapter,
	createSlice,
} from '@reduxjs/toolkit';
import { openDelete } from './appSlice';
import { logout } from './userSlice';
import atsApi from '../../api/atsApi';

export const createPost = createAsyncThunk(
	'post/create_post',
	async (data, { rejectWithValue }) => {
		try {
			const res = await atsApi.post('/posts', data);
			return res.data;
		} catch (err) {
			return rejectWithValue(err.response.data);
		}
	}
);

export const getAllPosts = createAsyncThunk(
	'post/get_all_posts',
	async (data, { rejectWithValue }) => {
		try {
			const res = await atsApi.get('/posts');
			return res.data;
		} catch (err) {
			return rejectWithValue(err.response.data);
		}
	}
);

export const getPostsByCategory = createAsyncThunk(
	'post/get_category',
	async (data, { rejectWithValue }) => {
		try {
			const res = await atsApi.get(`/posts/?category=${data}`);
			return res.data;
		} catch (err) {
			return rejectWithValue(err.response.data);
		}
	}
);

export const getPostById = createAsyncThunk(
	'post/get_id',
	async (data, { rejectWithValue }) => {
		try {
			const res = await atsApi.get(`/posts/?id=${data}`);
			return res.data;
		} catch (err) {
			return rejectWithValue(err.response.data);
		}
	}
);

export const updatePost = createAsyncThunk(
	'post/update_post',
	async (data, { rejectWithValue, dispatch }) => {
		const { id, ...others } = data;
		try {
			const res = await atsApi.put(`/posts/${id}`, others);
			const { success } = res.data;
			if (success) dispatch(getAllPosts());
			return res.data;
		} catch (err) {
			return rejectWithValue(err.response.data);
		}
	}
);

export const managePostMedia = createAsyncThunk(
	'post/manage_media',
	async (data, { rejectWithValue, dispatch }) => {
		const postId = data.get('postId');
		try {
			const res = await atsApi.put(`/posts/${postId}/media`, data);
			const { success } = res.data;
			if (success) {
				dispatch(openDelete(false));
			}
			return res.data;
		} catch (err) {
			return rejectWithValue(err.response.data);
		}
	}
);

export const deletePost = createAsyncThunk(
	'post/delete_post',
	async (data, { rejectWithValue, dispatch }) => {
		try {
			const res = await atsApi.delete(`/posts/${data}`);
			const { success } = res.data;
			if (success) {
				dispatch(getAllPosts());
				dispatch(openDelete(false));
			}
			return success;
		} catch (err) {
			return rejectWithValue(err.response.data);
		}
	}
);

export const postAdapter = createEntityAdapter();
const initialState = postAdapter.getInitialState({
	loading: false,
	postType: 'blog',
	title: '',
	categories: [],
	content: '',
	media: null,
	mediaPreview: null,
	videoAddress: '',
	allPosts: null,
	selectedPost: null,
	success: null,
	errors: null,
});

export const postSlice = createSlice({
	name: 'post',
	initialState,
	reducers: {
		setPostType: (state, action) => {
			state.postType = action.payload;
		},
		setTitle: (state, action) => {
			state.title = action.payload;
		},
		setCategories: (state, action) => {
			state.categories = action.payload;
		},
		setContent: (state, action) => {
			state.content = action.payload;
		},
		setMedia: (state, action) => {
			state.media = action.payload;
		},
		setMediaPreview: (state, action) => {
			state.mediaPreview = action.payload;
		},
		setVideoAddress: (state, action) => {
			state.videoAddress = action.payload;
		},
		setSelectedPost: (state, action) => {
			state.selectedPost = action.payload;
		},
		populatePostData: (state, action) => {
			state.postType = action.payload.postType;
			state.title = action.payload.title;
			state.categories = action.payload.categories;
			state.content = action.payload.content;
			state.media = action.payload.media;
			state.videoAddress = action.payload.videoAddress;
		},
		clearPostData: (state) => {
			state.postType = 'blog';
			state.title = '';
			state.categories = [];
			state.content = '';
			state.media = null;
			state.videoAddress = '';
		},
		clearSelectedPost: (state) => {
			state.selectedPost = null;
		},
		clearSuccess: (state) => {
			state.success = null;
		},
		clearErrors: (state) => {
			state.errors = null;
		},
	},
	extraReducers: (builder) => {
		builder
			.addCase(createPost.pending, (state) => {
				state.loading = true;
				state.errors = null;
			})
			.addCase(createPost.fulfilled, (state, action) => {
				state.loading = false;
				state.success = action.payload.success;
				state.allPosts = action.payload.posts;
				state.selectedPost = action.payload.post;
				state.title = '';
				state.content = '';
				state.media = null;
				state.videoAddress = '';
				state.categories = [];
				state.postType = 'blog';
			})
			.addCase(createPost.rejected, (state, action) => {
				state.loading = false;
				state.errors = action.payload;
			})
			.addCase(getAllPosts.pending, (state) => {
				state.loading = true;
				state.errors = null;
			})
			.addCase(getAllPosts.fulfilled, (state, action) => {
				state.loading = false;
				state.allPosts = action.payload;
			})
			.addCase(getAllPosts.rejected, (state, action) => {
				state.loading = false;
				state.errors = action.payload;
			})
			.addCase(getPostsByCategory.pending, (state) => {
				state.loading = true;
				state.errors = null;
			})
			.addCase(getPostsByCategory.fulfilled, (state, action) => {
				state.loading = false;
				state.allPosts = action.payload;
			})
			.addCase(getPostsByCategory.rejected, (state, action) => {
				state.loading = false;
				state.errors = action.payload;
			})
			.addCase(getPostById.pending, (state) => {
				state.loading = true;
				state.errors = null;
			})
			.addCase(getPostById.fulfilled, (state, action) => {
				state.loading = false;
				state.selectedPost = action.payload;
			})
			.addCase(getPostById.rejected, (state, action) => {
				state.loading = false;
				state.errors = action.payload;
			})
			.addCase(updatePost.pending, (state) => {
				state.loading = true;
				state.errors = null;
			})
			.addCase(updatePost.fulfilled, (state, action) => {
				state.loading = false;
				state.success = action.payload.success;
				state.selectedPost = action.payload.updated;
			})
			.addCase(updatePost.rejected, (state, action) => {
				state.loading = false;
				state.errors = action.payload;
			})
			.addCase(managePostMedia.pending, (state) => {
				state.loading = true;
				state.errors = null;
			})
			.addCase(managePostMedia.fulfilled, (state, action) => {
				state.loading = false;
				state.success = action.payload.success;
				state.selectedPost = action.payload.updated;
				state.allPosts = action.payload.allPosts;
				state.mediaPreview = null;
			})
			.addCase(managePostMedia.rejected, (state, action) => {
				state.loading = false;
				state.errors = action.payload;
			})
			.addCase(deletePost.pending, (state) => {
				state.loading = true;
				state.errors = null;
			})
			.addCase(deletePost.fulfilled, (state, action) => {
				state.loading = false;
				state.success = action.payload;
				state.selectedPost = null;
			})
			.addCase(deletePost.rejected, (state, action) => {
				state.loading = false;
				state.errors = action.payload;
			})
			.addCase(logout, (state) => {
				state.loading = false;
				state.title = '';
				state.content = '';
				state.media = null;
				state.mediaPreview = null;
				state.videoAddress = '';
				state.categories = [];
				state.postType = 'blog';
				state.allPosts = null;
				state.selectedPost = null;
				state.success = null;
				state.errors = null;
				postAdapter.removeAll(state);
			});
	},
});

export const {
	setTitle,
	setContent,
	setMedia,
	setMediaPreview,
	setVideoAddress,
	setCategories,
	setPostType,
	setSelectedPost,
	populatePostData,
	clearPostData,
	clearSelectedPost,
	clearSuccess,
	clearErrors,
} = postSlice.actions;

export default postSlice.reducer;
