import { Box } from '@mui/material';
import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setIsMobile } from '../../redux/slices/appSlice';
import { breakpoints } from '../../util/data';
import Header from './components/Header';
import SideNav from './components/SideNav';
import Main from './components/Main';
import DeleteDialog from '../../components/DeleteDialog';
import PhotoUploadDialog from '../../components/PhotoUploadDialog';

const AdminLayout = ({ children }) => {
	const { deleteDialog, deleteData } = useSelector((state) => state.app);
	const dispatch = useDispatch();

	const handleMobile = useCallback(() => {
		const handleResize = () => {
			if (window.innerWidth <= breakpoints.md) {
				dispatch(setIsMobile(true));
			} else {
				dispatch(setIsMobile(false));
			}
		};

		window.addEventListener('resize', handleResize);
		handleResize();

		return () => {
			window.removeEventListener('resize', handleResize);
		};
	}, [dispatch]);

	useEffect(() => {
		handleMobile();
	}, [handleMobile]);
	return (
		<>
			<Header />
			<Box
				sx={{
					minHeight: 1,
					display: 'flex',
					flexDirection: { xs: 'column', lg: 'row' },
				}}
			>
				<SideNav />
				<Main>{children}</Main>
			</Box>
			<DeleteDialog open={deleteDialog} data={deleteData} />
			<PhotoUploadDialog />
		</>
	);
};

export default AdminLayout;
