import io from 'socket.io-client';
let connected = false;

export const socket = io.connect('https://ats-backend-hr1h.onrender.com');

socket.on('connected', () => {
	connected = true;
	console.log('You are connected!');
});

socket.on('newInterest', (user) =>
	console.log('New user Id:', user.firstName + ' ' + user.lastName)
);

socket.on('joined', () => console.log('Joined successfully!'));
socket.on('typing', () => console.log('someone is typing'));
socket.on('message received', () => console.log("You've got mail"));
