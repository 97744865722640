import {
	Card,
	CardContent,
	FormControl,
	Stack,
	TextField,
} from '@mui/material';
import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
	setEmail,
	generatePasswordToken,
	clearSuccess,
	clearErrors,
} from '../../redux/slices/userSlice';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import './forgot.scss';
import Button from '../../components/Button';

const ForgotPassword = () => {
	const { loading, email, success, errors } = useSelector(
		(state) => state.user
	);
	const dispatch = useDispatch();

	const handleFocus = () => {
		dispatch(clearErrors());
	};

	const handleChange = (e) => {
		dispatch(setEmail(e.target.value));
	};

	const handleSubmit = (e) => {
		e.preventDefault();
		const data = {
			email: email.toLowerCase(),
		};
		dispatch(generatePasswordToken(data));
	};

	const handleSuccess = useCallback(() => {
		success &&
			setTimeout(() => {
				dispatch(clearSuccess());
			}, 7000);
	}, [success, dispatch]);

	useEffect(() => {
		handleSuccess();
	}, [handleSuccess]);

	return (
		<div id='forgot'>
			<Card id='forgot-password' elevation={12}>
				<CardContent className='container'>
					<Stack alignItems='center'>
						<h2>Forgot Password</h2>
						<HelpOutlineIcon className='icon' fontSize='large' />
					</Stack>
					<form onSubmit={handleSubmit}>
						<FormControl variant='standard'>
							<TextField
								type='email'
								label='Email'
								size='small'
								margin='dense'
								value={email}
								onFocus={handleFocus}
								onChange={handleChange}
							/>
							{errors?.email && <h6 className='error'>{errors?.email}</h6>}
						</FormControl>
						<Button
							type='submit'
							loading={loading}
							label='SUBMIT'
							btnClass='forgot-btn'
						/>
					</form>
				</CardContent>
				<div className='response-container'>
					{success?.message && (
						<p className='success'>
							<span>
								<CheckCircleOutlineIcon fontSize='small' />
							</span>
							{success?.message}
						</p>
					)}
					{errors?.message && (
						<p className='error'>
							<span>
								<ErrorOutlineIcon fontSize='small' />
							</span>
							{errors?.message}
						</p>
					)}
				</div>
			</Card>
		</div>
	);
};

export default ForgotPassword;
