import QRCode from 'qrcode.react';

const QrGenerator = () => {
	// const contactInfo =
	// 	'BEGIN:VCARD\nVERSION:3.0\nFN:Katie Budaj\nORG:Aligned To Shine\nTITLE:Owner/Coach\nEMAIL:katie@alignedtoshine.org\nURL:https://alignedtoshine.org\nTEL:858-449-9212\nEND:VCARD';

	return (
		<QRCode
			value='https://www.canva.com/design/DAGAA8V92T8/4CZA85J_v-OfiBsHAHc7dg/view?utm_content=DAGAA8V92T8&utm_campaign=designshare&utm_medium=link&utm_source=editor'
			imageSettings={{
				src: '/logo192.png',
				x: undefined,
				y: undefined,
				width: 24,
				height: 24,
				excavate: true,
			}}
		/>
	);
};

export default QrGenerator;
