import { Box } from '@mui/material';
import './main.scss';

const Main = ({ children, sx, ...other }) => {
	return (
		<Box
			id='main-content'
			component='main'
			sx={{
				...sx,
			}}
			{...other}
		>
			{children}
		</Box>
	);
};

export default Main;
