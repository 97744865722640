import { Container, Paper } from '@mui/material';
import { useSelector } from 'react-redux';
import './watch.scss';

const Watch = () => {
	const { singleContent } = useSelector((state) => state.content);

	return (
		<div id='watch'>
			<Container maxWidth='lg' className='watch-container'>
				<Paper className='content-title' elevation={10}>
					<h1>{singleContent?.title}</h1>
				</Paper>
				<div className='video-container'>
					<iframe
						title={singleContent?.title}
						src={singleContent?.url}
						frameBorder='0'
						allow='autoplay; encrypted-media;'
						allowFullScreen
					/>
				</div>
			</Container>
		</div>
	);
};

export default Watch;
