import { useSelector } from 'react-redux';
import Slider from 'react-slick';
import './carousel.scss';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const Carousel = () => {
	const { selectedPost } = useSelector((state) => state.post);
	const media = selectedPost?.media;
	const settings = {
		infinite: true,
		speed: 500,
		slidesToShow: 1,
		slidesToScroll: 1,
	};

	return (
		<>
			{media?.length === 1 ? (
				<div className='single-image'>
					<a
						href={media[0]}
						className='pic-btn'
						target='_blank'
						rel='noreferrer'
					>
						<div className='pic-wrapper'>
							<img src={media[0]} alt='' />
						</div>
					</a>
				</div>
			) : media?.length > 1 ? (
				<Slider {...settings} className='slide' autoplay>
					{media?.map((item, i) => (
						<a
							key={i}
							href={item}
							className='pic-btn'
							target='_blank'
							rel='noreferrer'
						>
							<div className='pic-wrapper'>
								<img src={item} alt='' />
							</div>
						</a>
					))}
				</Slider>
			) : null}
		</>
	);
};

export default Carousel;
