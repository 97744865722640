import {
	Alert,
	Button,
	FormControl,
	FormControlLabel,
	FormLabel,
	Grid,
	IconButton,
	Radio,
	RadioGroup,
	Paper,
	Snackbar,
	Stack,
	Typography,
} from '@mui/material';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setTheme } from '../../../../redux/slices/appSlice';
import {
	populateUser,
	setFirstName,
	setLastName,
	setUsername,
	setEmail,
	setMobilePhone,
	setHomePhone,
	setWorkPhone,
	setEmailComm,
	setSmsComm,
	setVoiceComm,
	setSnailComm,
	updateUser,
	clearSuccess,
	clearUser,
	clearErrors,
} from '../../../../redux/slices/userSlice';
import PhotoCameraOutlinedIcon from '@mui/icons-material/PhotoCameraOutlined';
import './profile.scss';
import Section from '../../../../components/Section';
import ThemeSwitch from '../../components/ThemeSwitch';
import TextInput from '../../../../components/TextInput';
import ProfileImagesDialog from '../../components/ProfileImagesDialog';

const Profile = ({ profileType }) => {
	const [open, setOpen] = useState(false);
	const [imgDialogOpen, setImgDialogOpen] = useState(false);
	const [imgDialogType, setImgDialogType] = useState('profile');
	const { theme } = useSelector((state) => state.app);
	const {
		user,
		firstName,
		lastName,
		username,
		email,
		phone,
		communication,
		success,
		errors,
	} = useSelector((state) => state.user);
	const dispatch = useDispatch();

	const handleImgDialogToggle = () => {
		setImgDialogOpen(!imgDialogOpen);
	};

	const handleCover = () => {
		setImgDialogType('cover');
		handleImgDialogToggle();
	};

	const handleProfile = () => {
		setImgDialogType('profile');
		handleImgDialogToggle();
	};

	const switchTheme = () => {
		const newTheme = theme === 'light' ? 'dark' : 'light';
		dispatch(setTheme(newTheme));
	};

	const handleFocus = () => {
		dispatch(clearErrors());
	};

	const handleChange = (input, value) => {
		const actionMap = {
			first: setFirstName,
			last: setLastName,
			username: setUsername,
			email: setEmail,
			mobile: setMobilePhone,
			home: setHomePhone,
			work: setWorkPhone,
			emailComm: setEmailComm,
			smsComm: setSmsComm,
			voiceComm: setVoiceComm,
			snailComm: setSnailComm,
		};

		const action = actionMap[input];

		action && dispatch(action(value));
	};

	const handleSubmit = (e) => {
		e.preventDefault();
		const data = {
			_id: user?._id,
			...(firstName !== user.firstName && { firstName }),
			...(lastName !== user.lastName && { lastName }),
			...(username !== user.username && { username }),
			...(email !== user.email && { email }),
			...(phone !== user.phone && { phone }),
			...(communication !== user.communication && { communication }),
		};

		dispatch(updateUser(data));
	};

	const handleClose = () => {
		setOpen(false);
		dispatch(clearSuccess());
	};

	const loadUser = useCallback(() => {
		dispatch(populateUser());
	}, [dispatch]);

	const resetUser = useCallback(() => {
		dispatch(clearUser());
	}, [dispatch]);

	useEffect(() => {
		loadUser();
		return () => {
			resetUser();
		};
	}, [user, loadUser, resetUser]);

	useEffect(() => {
		if (success) {
			setOpen(true);
			setImgDialogOpen(false);
		}
	}, [success]);

	return (
		<div id='profile'>
			<div className='header-container'>
				<div className='cover-photo-section'>
					<div className='cover-photo-container'>
						{user?.coverPhoto && <img src={user?.coverPhoto} alt='cover' />}
						<IconButton className='cover-photo-btn' onClick={handleCover}>
							<PhotoCameraOutlinedIcon className='btn-icon' />
						</IconButton>
					</div>
				</div>
			</div>
			<Section id='profile-content' maxWidth='xl'>
				<Stack
					direction='column'
					justifyContent='center'
					alignItems='center'
					gap={1}
					className='user-container'
				>
					<div className='user-image-container'>
						<img src={user?.profilePic} alt='avatar' />
						<IconButton className='profile-pic-btn' onClick={handleProfile}>
							<PhotoCameraOutlinedIcon className='btn-icon' />
						</IconButton>
					</div>
					<Stack direction='column' alignItems='center'>
						<span className='full-name'>
							{user?.firstName + ' ' + user?.lastName}
						</span>
						<span className='username'>@{user?.username}</span>
						<span className='description'></span>
					</Stack>
				</Stack>
				<Paper
					className={theme === 'dark' ? 'profile-paper dark' : 'profile-paper'}
					sx={{
						height: {
							xs:
								profileType === 'member'
									? 'calc(100vh - 380px)'
									: profileType === 'admin' && 'calc(100vh - 390px)',
							sm:
								profileType === 'member'
									? 'calc(100vh - 390px)'
									: profileType === 'admin' && 'calc(100vh - 405px)',
						},
					}}
					elevation={10}
				>
					<div className='greeting-container'>
						<h3 className='greeting'>Hi {user?.firstName}, Welcome back 👋</h3>
						<Stack direction='row' alignItems='center'>
							<Typography>Light</Typography>
							<ThemeSwitch
								checked={theme === 'light' ? false : true}
								onChange={switchTheme}
							/>
							<Typography>Dark</Typography>
						</Stack>
					</div>
					<form action='' onSubmit={handleSubmit}>
						<h4 className='basic'>Basic Information</h4>
						<Grid container className='info-container' spacing={2}>
							<Grid item xs={12} sm={6} md={3} className='grid-item'>
								<TextInput
									label='First Name'
									size='small'
									margin='dense'
									value={firstName}
									onFocus={handleFocus}
									onChange={(e) => handleChange('first', e.target.value)}
								/>
							</Grid>
							<Grid item xs={12} sm={6} md={3} className='grid-item'>
								<TextInput
									label='Last Name'
									size='small'
									margin='dense'
									value={lastName}
									onFocus={handleFocus}
									onChange={(e) => handleChange('last', e.target.value)}
								/>
							</Grid>
							<Grid item xs={12} sm={6} md={3} className='grid-item'>
								<TextInput
									autoCap='none'
									label='Username'
									size='small'
									margin='dense'
									value={username}
									onFocus={handleFocus}
									onChange={(e) => handleChange('username', e.target.value)}
									error={errors?.username}
								/>
							</Grid>
							<Grid item xs={12} sm={6} md={3} className='grid-item'>
								<TextInput
									autoCap='none'
									type='email'
									label='Email'
									size='small'
									margin='dense'
									value={email}
									onFocus={handleFocus}
									onChange={(e) => handleChange('email', e.target.value)}
									error={errors?.email}
								/>
							</Grid>
						</Grid>
						<h4 className='phone'>Phone</h4>
						<Grid container className='info-container' spacing={2}>
							<Grid item xs={12} sm={6} md={4} className='grid-item'>
								<TextInput
									label='Mobile'
									size='small'
									margin='dense'
									value={phone.mobile}
									onFocus={handleFocus}
									onChange={(e) => handleChange('mobile', e.target.value)}
									error={errors?.mobile}
								/>
							</Grid>
							<Grid item xs={12} sm={6} md={4} className='grid-item'>
								<TextInput
									label='Home'
									size='small'
									margin='dense'
									value={phone.home}
									onFocus={handleFocus}
									onChange={(e) => handleChange('home', e.target.value)}
									error={errors?.home}
								/>
							</Grid>
							<Grid item xs={12} sm={6} md={4} className='grid-item'>
								<TextInput
									label='Work'
									size='small'
									margin='dense'
									value={phone.work}
									onFocus={handleFocus}
									onChange={(e) => handleChange('work', e.target.value)}
									error={errors?.work}
								/>
							</Grid>
						</Grid>
						<h4 className='comm'>Communications</h4>
						<Grid container className='info-container' spacing={2}>
							<Grid item xs={12} sm={3} className='grid-item'>
								<FormControl>
									<FormLabel id='comm-group'>Email</FormLabel>
									<RadioGroup
										aria-labelledby='comm-group'
										value={communication.email}
										onChange={(e) => handleChange('emailComm', e.target.value)}
									>
										<FormControlLabel
											value={'true'}
											control={<Radio />}
											label='Opt-In'
										/>
										<FormControlLabel
											value={'false'}
											control={<Radio />}
											label='Opt-Out'
										/>
									</RadioGroup>
								</FormControl>
							</Grid>
							<Grid item xs={12} sm={3} className='grid-item'>
								<FormControl>
									<FormLabel id='comm-group'>SMS</FormLabel>
									<RadioGroup
										aria-labelledby='comm-group'
										value={communication.sms}
										onChange={(e) => handleChange('smsComm', e.target.value)}
									>
										<FormControlLabel
											value={'true'}
											control={<Radio />}
											label='Opt-In'
										/>
										<FormControlLabel
											value={'false'}
											control={<Radio />}
											label='Opt-Out'
										/>
									</RadioGroup>
								</FormControl>
							</Grid>
							<Grid item xs={12} sm={3} className='grid-item'>
								<FormControl>
									<FormLabel id='comm-group'>Voice</FormLabel>
									<RadioGroup
										aria-labelledby='comm-group'
										value={communication.voice}
										onChange={(e) => handleChange('voiceComm', e.target.value)}
									>
										<FormControlLabel
											value={'true'}
											control={<Radio />}
											label='Opt-In'
										/>
										<FormControlLabel
											value={'false'}
											control={<Radio />}
											label='Opt-Out'
										/>
									</RadioGroup>
								</FormControl>
							</Grid>
							<Grid item xs={12} sm={3} className='grid-item'>
								<FormControl>
									<FormLabel id='comm-group'>Snail</FormLabel>
									<RadioGroup
										aria-labelledby='comm-group'
										value={communication.snail}
										onChange={(e) => handleChange('snailComm', e.target.value)}
									>
										<FormControlLabel
											value={'true'}
											control={<Radio />}
											label='Opt-In'
										/>
										<FormControlLabel
											value={'false'}
											control={<Radio />}
											label='Opt-Out'
										/>
									</RadioGroup>
								</FormControl>
							</Grid>
						</Grid>
						<Button type='submit'>Submit</Button>
					</form>
				</Paper>
			</Section>
			<Snackbar open={open} autoHideDuration={7000} onClose={handleClose}>
				<Alert severity='success'>{success?.message}</Alert>
			</Snackbar>
			<ProfileImagesDialog
				open={imgDialogOpen}
				type={imgDialogType}
				handleClose={handleImgDialogToggle}
			/>
		</div>
	);
};

export default Profile;
