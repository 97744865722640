import { TableCell, TableRow } from '@mui/material';

const TableEmptyRows = ({ emptyRows, height }) => {
	if (!emptyRows) {
		return null;
	}

	return (
		<TableRow
			sx={{
				...(height && {
					height: height * emptyRows,
				}),
			}}
		>
			<TableCell colSpan={9} />
		</TableRow>
	);
};

export default TableEmptyRows;
