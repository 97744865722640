import Topbar from '../../components/Topbar';
import Spacer from '../../components/Spacer';

const MemberLayout = ({ children }) => {
	return (
		<>
			<Topbar />
			<Spacer />
			{children}
		</>
	);
};

export default MemberLayout;
