import { Avatar, Box, Divider, Stack, Typography } from '@mui/material';
import { useSelector } from 'react-redux';
import { adminNav } from '../../../../../../util/data';
import './content.scss';
import NavItem from '../NavItem';

const NavContent = () => {
	const { user } = useSelector((state) => state.user);

	return (
		<div id='nav-content'>
			<div id='logo-container'>
				<img src='/head-logo.png' alt='logo' />
			</div>
			<Divider />
			<Box
				sx={{
					my: 3,
					mx: 2.5,
					py: 2,
					px: 2.5,
					display: 'flex',
					borderRadius: 1.5,
					alignItems: 'center',
					bgcolor: 'rgba(171, 171, 171, .12)',
				}}
			>
				<Avatar src={user?.profilePic} alt={user?.username} />
				<Box sx={{ ml: 2 }}>
					<Typography variant='subtitle2'>
						{user?.firstName + ' ' + user?.lastName}
					</Typography>

					<Typography variant='body2' sx={{ color: 'text.secondary' }}>
						{user?.role.charAt(0).toUpperCase() + user?.role.slice(1)}
					</Typography>
				</Box>
			</Box>
			<Stack component='nav' spacing={0.5} sx={{ px: 2 }}>
				{adminNav?.map((item) => (
					<NavItem key={item.title} item={item} />
				))}
			</Stack>
		</div>
	);
};

export default NavContent;
