import { Button, Paper } from '@mui/material';
import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { getAllPosts } from '../../redux/slices/postSlice';
import AddIcon from '@mui/icons-material/Add';
import './blog.scss';
import Section from '../../components/Section';
import BlogItem from '../../components/BlogItem';

const Blog = () => {
	const { user } = useSelector((state) => state.user);
	const { allPosts } = useSelector((state) => state.post);
	const navigate = useNavigate();
	const dispatch = useDispatch();

	const handleCreateNewClick = () => {
		navigate('/admin/create-post');
	};

	const handleGetPosts = useCallback(() => {
		dispatch(getAllPosts());
	}, [dispatch]);

	useEffect(() => {
		handleGetPosts();

		return () => {};
	}, [handleGetPosts]);

	return (
		<Section id='blog' maxWidth='lg'>
			<Paper className='wrapper' elevation={5}>
				<div className='page-header'>
					{user && (
						<div className='create-new'>
							<Button
								id='create-new-btn'
								variant='contained'
								color='inherit'
								startIcon={<AddIcon />}
								onClick={handleCreateNewClick}
							>
								Create New
							</Button>
						</div>
					)}
				</div>
				<div className='heading'>
					<h2>Past Blogs</h2>
				</div>
				<div className='main'>
					{allPosts?.map((post) => (
						<BlogItem key={post._id} post={post} />
					))}
				</div>
			</Paper>
		</Section>
	);
};

export default Blog;
