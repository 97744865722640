import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { setSingle } from '../../../../../../redux/slices/contentSlice';
import './featured.scss';
import Section from '../../../../../../components/Section';
import Button from '../../../../../../components/Button';

const Featured = ({ sectionClass, featuredClass, item }) => {
	const navigate = useNavigate();
	const dispatch = useDispatch();

	const handleClick = () => {
		dispatch(setSingle(item));
		navigate(`/featured/${item.title.replace(/\s/g, '-').toLowerCase()}`);
	};

	return (
		<Section className={`featured ${sectionClass}`} maxWidth='lg'>
			<div className={`featured-container ${featuredClass}`}>
				<div className='pic-container'>
					<img
						src='https://kajabi-storefronts-production.kajabi-cdn.com/kajabi-storefronts-production/file-uploads/themes/2150652960/settings_images/3ea5345-405-814-7dd5-d381ab7d513e_937b0660-b93f-41ee-8a72-bc31698e8529.png'
						alt=''
					/>
				</div>
				<div className='info-container'>
					<h2>{item.title}</h2>
					<p>Checkout this video, you might learn something!</p>
				</div>
			</div>
			<div className='btn-container'>
				<Button
					label='Watch The Video'
					btnClass='lnk-btn'
					onClick={handleClick}
				/>
			</div>
		</Section>
	);
};

export default Featured;
