import {
	Button,
	Checkbox,
	Grid,
	List,
	ListItemButton,
	ListItemIcon,
	ListItemText,
	Paper,
} from '@mui/material';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setAllCategories } from '../../redux/slices/categorySlice';
import { setCategories } from '../../redux/slices/postSlice';
import { not, intersection } from '../../util/helpers';
import './transfer.scss';

const TransferList = ({ isUpdate }) => {
	const { user } = useSelector((state) => state.user);
	let { allCategories } = useSelector((state) => state.category);
	const { categories } = useSelector((state) => state.post);
	const [checked, setChecked] = useState([]);
	const dispatch = useDispatch();
	const theme = user?.theme;

	if (isUpdate) {
		allCategories = allCategories.filter(
			(item) => !categories.some((category) => category._id === item._id)
		);
	}

	const leftChecked = intersection(checked, allCategories);
	const rightChecked = intersection(checked, categories);

	const handleToggle = (item) => () => {
		const currentIndex = checked.indexOf(item);
		const newChecked = [...checked];

		if (currentIndex === -1) {
			newChecked.push(item);
		} else {
			newChecked.splice(currentIndex, 1);
		}

		setChecked(newChecked);
	};

	const handleAllRight = () => {
		dispatch(setCategories(categories.concat(allCategories)));
		dispatch(setAllCategories([]));
	};

	const handleCheckedRight = () => {
		dispatch(setCategories(categories.concat(leftChecked)));
		dispatch(setAllCategories(not(allCategories, leftChecked)));
		setChecked(not(checked, leftChecked));
	};

	const handleCheckedLeft = () => {
		dispatch(setAllCategories(allCategories.concat(rightChecked)));
		dispatch(setCategories(not(categories, rightChecked)));
		setChecked(not(checked, rightChecked));
	};

	const handleAllLeft = () => {
		dispatch(setAllCategories(allCategories.concat(categories)));
		dispatch(setCategories([]));
	};

	const customList = (items) => (
		<Paper
			className={theme === 'dark' ? 'transfer-paper dark' : 'transfer-paper'}
		>
			<List dense component='div' role='list'>
				{items?.map((item) => {
					const labelId = `transfer-list-item-${item.category}-label`;

					return (
						<ListItemButton
							key={item._id}
							role='listitem'
							onClick={handleToggle(item)}
						>
							<ListItemIcon>
								<Checkbox
									checked={checked.indexOf(item) !== -1}
									tabIndex={-1}
									disableRipple
									inputProps={{
										'aria-labelledby': labelId,
									}}
								/>
							</ListItemIcon>
							<ListItemText id={labelId} primary={item.category} />
						</ListItemButton>
					);
				})}
			</List>
		</Paper>
	);

	return (
		<Grid
			container
			spacing={2}
			justifyContent='center'
			alignItems='center'
			id='transfer-list'
		>
			<Grid item>{customList(allCategories)}</Grid>
			<Grid item>
				<Grid container direction='column' alignItems='center'>
					<Button
						sx={{ my: 0.5 }}
						variant='outlined'
						size='small'
						onClick={handleAllRight}
						disabled={allCategories?.length === 0}
						aria-label='move all right'
					>
						≫
					</Button>
					<Button
						sx={{ my: 0.5 }}
						variant='outlined'
						size='small'
						onClick={handleCheckedRight}
						disabled={leftChecked?.length === 0}
						aria-label='move selected right'
					>
						&gt;
					</Button>
					<Button
						sx={{ my: 0.5 }}
						variant='outlined'
						size='small'
						onClick={handleCheckedLeft}
						disabled={rightChecked?.length === 0}
						aria-label='move selected left'
					>
						&lt;
					</Button>
					<Button
						sx={{ my: 0.5 }}
						variant='outlined'
						size='small'
						onClick={handleAllLeft}
						disabled={categories?.length === 0}
						aria-label='move all left'
					>
						≪
					</Button>
				</Grid>
			</Grid>
			<Grid item>{customList(categories)}</Grid>
		</Grid>
	);
};

export default TransferList;
