import { Box, ListItemButton } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { setDrawerOpen } from '../../../../../../redux/slices/userSlice';
import RouterLink from '../../../../../../components/RouterLink';

const NavItem = ({ item }) => {
	const { isMobile } = useSelector((state) => state.app);
	const { pathname } = useLocation();
	const dispatch = useDispatch();

	const active = item.path === pathname;

	const handleClick = () => {
		dispatch(setDrawerOpen(false));
	};

	return (
		<ListItemButton
			component={RouterLink}
			href={item.path}
			onClick={isMobile ? handleClick : null}
			sx={{
				minHeight: 44,
				borderRadius: 0.75,
				typography: 'body2',
				color: 'text.secondary',
				textTransform: 'capitalize',
				fontWeight: 'fontWeightMedium',
				...(active && {
					color: 'primary.main',
					fontWeight: 'fontWeightSemiBold',
					bgcolor: 'rgba(23, 118, 242, .08)',
					'&:hover': {
						bgcolor: 'rgba(23, 118, 242, .16)',
					},
				}),
			}}
		>
			<Box component='span' sx={{ width: 24, height: 24, mr: 2 }}>
				{item.icon}
			</Box>
			<Box component='span'>{item.title}</Box>
		</ListItemButton>
	);
};

export default NavItem;
