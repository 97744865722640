import {
	IconButton,
	InputAdornment,
	OutlinedInput,
	Toolbar,
	Tooltip,
	Typography,
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import FilterListIcon from '@mui/icons-material/FilterList';

const UserTableToolbar = ({ numSelected, filterName, onFilterName }) => {
	return (
		<Toolbar
			sx={{
				height: 96,
				display: 'flex',
				justifyContent: 'space-between',
				p: (theme) => theme.spacing(0, 1, 0, 3),
				...(numSelected > 0 && {
					color: 'primary.main',
					bgcolor: 'primary.lighter',
				}),
			}}
		>
			{numSelected > 0 ? (
				<Typography component='div' variant='subtitle1'>
					{numSelected} selected
				</Typography>
			) : (
				<OutlinedInput
					size='small'
					value={filterName}
					onChange={onFilterName}
					placeholder='Search user...'
					startAdornment={
						<InputAdornment position='start'>
							<SearchIcon />
						</InputAdornment>
					}
				/>
			)}

			{numSelected > 0 ? (
				<Tooltip title='Delete'>
					<IconButton>
						<DeleteForeverIcon />
					</IconButton>
				</Tooltip>
			) : (
				<Tooltip title='Filter list'>
					<IconButton>
						<FilterListIcon />
					</IconButton>
				</Tooltip>
			)}
		</Toolbar>
	);
};

export default UserTableToolbar;
