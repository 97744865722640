import React from 'react';
import './aboutMe.scss';
import Section from '../../../../components/Section';
import Footer from '../../../../components/Footer';

const AboutMe = () => {
	return (
		<div id='about-me'>
			<Section id='about-header' maxWidth='lg'>
				<div className='header-container'>
					<div className='txt-container'>
						<h1>Meet Katie</h1>
					</div>

					<div className='pic-container'>
						<img src='/head-shot.png' alt='' />
					</div>
				</div>
			</Section>
			<Section id='about-description' maxWidth='lg'>
				<div className='description-container'>
					<p>
						Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
						eiusmod tempor incididunt ut labore et dolore magna aliqua. Semper
						quis lectus nulla at. Sed libero enim sed faucibus. Ipsum dolor sit
						amet consectetur adipiscing elit. Amet mattis vulputate enim nulla.
						Magna sit amet purus gravida quis blandit. Aliquam purus sit amet
						luctus. Facilisis volutpat est velit egestas dui id. Aliquam sem
						fringilla ut morbi tincidunt augue interdum. Sodales ut etiam sit
						amet nisl purus in. Eget sit amet tellus cras adipiscing. Nulla
						posuere sollicitudin aliquam ultrices sagittis. Eros in cursus
						turpis massa tincidunt dui. In hac habitasse platea dictumst quisque
						sagittis purus sit. Et odio pellentesque diam volutpat. Ut consequat
						semper viverra nam. Amet nisl suscipit adipiscing bibendum est
						ultricies integer quis auctor. Velit sed ullamcorper morbi tincidunt
						ornare massa.
					</p>

					<p>
						Tristique sollicitudin nibh sit amet commodo nulla. A erat nam at
						lectus urna duis convallis convallis. Turpis massa tincidunt dui ut
						ornare. Quisque sagittis purus sit amet volutpat consequat mauris
						nunc. Vitae turpis massa sed elementum tempus egestas sed. Risus
						pretium quam vulputate dignissim suspendisse. Diam vulputate ut
						pharetra sit. Convallis aenean et tortor at risus viverra adipiscing
						at. Aliquet sagittis id consectetur purus ut faucibus. In hac
						habitasse platea dictumst quisque sagittis purus. Venenatis lectus
						magna fringilla urna porttitor rhoncus dolor. Donec ac odio tempor
						orci dapibus ultrices in. Rutrum quisque non tellus orci. Imperdiet
						dui accumsan sit amet nulla facilisi morbi. Aliquet eget sit amet
						tellus.
					</p>

					<p>
						At volutpat diam ut venenatis tellus in metus vulputate eu.
						Elementum facilisis leo vel fringilla est ullamcorper eget. Aliquet
						risus feugiat in ante. Morbi tristique senectus et netus et
						malesuada fames. Vitae proin sagittis nisl rhoncus mattis rhoncus
						urna neque viverra. Nunc consequat interdum varius sit amet mattis
						vulputate enim. Adipiscing tristique risus nec feugiat in fermentum
						posuere. Magna fermentum iaculis eu non diam. Vel risus commodo
						viverra maecenas accumsan. Faucibus purus in massa tempor nec. Id
						ornare arcu odio ut sem nulla. Commodo nulla facilisi nullam
						vehicula ipsum a arcu cursus vitae. Sodales ut eu sem integer vitae
						justo. Viverra ipsum nunc aliquet bibendum enim. Sit amet aliquam id
						diam maecenas ultricies mi. Cras ornare arcu dui vivamus arcu felis.
						Eget nullam non nisi est sit amet. Et ultrices neque ornare aenean
						euismod elementum.
					</p>

					<p>
						Elementum nisi quis eleifend quam adipiscing. Quam quisque id diam
						vel. Dolor purus non enim praesent elementum. Convallis convallis
						tellus id interdum velit laoreet id donec. Enim tortor at auctor
						urna nunc. Tempus urna et pharetra pharetra. Sem nulla pharetra diam
						sit amet nisl suscipit adipiscing bibendum. At elementum eu
						facilisis sed odio morbi quis commodo odio. Neque convallis a cras
						semper auctor neque vitae. Pharetra et ultrices neque ornare aenean
						euismod elementum nisi. At risus viverra adipiscing at.
					</p>
				</div>
			</Section>
			<Footer />
		</div>
	);
};

export default AboutMe;
