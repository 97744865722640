import { AppBar, Toolbar } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import './home.scss';
import Spacer from '../../components/Spacer';
import Button from '../../components/Button';
import Hero from './components/Hero';
import About from './components/About';
import Offering from './components/Offering';
import Interest from './components/Interest';
import Footer from '../../components/Footer';

const Home = () => {
	const navigate = useNavigate();

	const handleClick = () => {
		navigate('/login');
	};

	return (
		<div id='home'>
			<AppBar
				sx={{
					boxShadow: 'none',
					height: { xs: 56, sm: 64 },
					zIndex: 1101,
					bgcolor: '#f7f7f7',
				}}
			>
				<Toolbar sx={{ justifyContent: 'center', px: 2 }}>
					<Button
						label='Member Login'
						btnClass='lnk-btn'
						onClick={handleClick}
					/>
				</Toolbar>
			</AppBar>
			<Spacer />
			<Hero />
			<About />
			<Offering />
			<Interest />
			<Footer />
		</div>
	);
};

export default Home;
