import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
	TextField,
} from '@mui/material';
import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
	setContentType,
	setCategory,
	setTitle,
	setUrl,
	setCost,
	setTags,
	addContent,
	clearSuccess,
	clearErrors,
} from '../../../../redux/slices/contentSlice';
import { setContentDialog } from '../../../../redux/slices/appSlice';

const ContentDialog = () => {
	const { contentDialog } = useSelector((state) => state.app);
	const { type, category, title, url, cost, tags, success } = useSelector(
		(state) => state.content
	);
	const dispatch = useDispatch();

	const handleClose = () => {
		dispatch(setContentDialog(false));
	};

	const handleFocus = () => {
		dispatch(clearErrors());
	};

	const handleChange = (input, value) => {
		const actionMap = {
			type: setContentType,
			cat: setCategory,
			title: setTitle,
			url: setUrl,
			cost: setCost,
			tags: setTags,
		};

		const action = actionMap[input];

		action && dispatch(action(value));
	};

	const handleSubmit = () => {
		const data = {
			type,
			category,
			title,
			url,
			cost,
		};

		dispatch(addContent(data));
	};

	const handleSuccess = useCallback(() => {
		if (success) {
			dispatch(setContentDialog(false));
			setTimeout(() => {
				dispatch(clearSuccess());
			}, 5000);
		}
	}, [success, dispatch]);

	useEffect(() => {
		handleSuccess();
	}, [handleSuccess]);

	return (
		<Dialog open={contentDialog} onClose={handleClose} maxWidth='xs' fullWidth>
			<DialogTitle>Add/Update Content</DialogTitle>
			<DialogContent>
				<TextField
					margin='dense'
					label='Type'
					fullWidth
					variant='standard'
					value={type}
					onFocus={handleFocus}
					onChange={(e) => handleChange('type', e.target.value)}
				/>
				<TextField
					margin='dense'
					label='Category'
					fullWidth
					variant='standard'
					value={category}
					onFocus={handleFocus}
					onChange={(e) => handleChange('cat', e.target.value)}
				/>
				<TextField
					margin='dense'
					label='Title'
					fullWidth
					variant='standard'
					value={title}
					onFocus={handleFocus}
					onChange={(e) => handleChange('title', e.target.value)}
				/>
				<TextField
					margin='dense'
					label='Url'
					fullWidth
					variant='standard'
					value={url}
					onFocus={handleFocus}
					onChange={(e) => handleChange('url', e.target.value)}
				/>
				<TextField
					margin='dense'
					label='Cost'
					fullWidth
					variant='standard'
					value={cost}
					onFocus={handleFocus}
					onChange={(e) => handleChange('cost', e.target.value)}
				/>
			</DialogContent>
			<DialogActions>
				<Button onClick={handleSubmit}>Submit</Button>
			</DialogActions>
		</Dialog>
	);
};

export default ContentDialog;
