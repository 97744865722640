import React from 'react';
import './offering.scss';
import Section from '../../../../components/Section';

const Offering = () => {
	return (
		<Section id='offering' maxWidth='lg'>
			Offering
		</Section>
	);
};

export default Offering;
