import { Typography } from '@mui/material';
import './dashboard.scss';
import Section from '../../../../components/Section';

const AdminDashbord = () => {
	return (
		<Section id='admin-dashboard' maxWidth='xl'>
			<Typography variant='h5' sx={{ mb: 2 }}>
				Hi, Welcome back 👋
			</Typography>
		</Section>
	);
};

export default AdminDashbord;
