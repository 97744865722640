import React from 'react';
import './hero.scss';
import Section from '../../../../components/Section';
import Button from '../../../../components/Button';

const Hero = () => {
	return (
		<Section id='hero' maxWidth='md'>
			<span>CALLING ALL ENTREPRENEURS WITH ADHD...</span>
			<h1>
				Eliminate the ADHD Challenges That Are Sabotaging Your Business Growth
			</h1>
			<Button btnClass='hero-btn lnk-btn' label='Watch Our Training' />
		</Section>
	);
};

export default Hero;
