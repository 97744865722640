import {
	Button,
	Checkbox,
	Chip,
	Divider,
	FormControl,
	FormControlLabel,
	IconButton,
	Paper,
	Radio,
	RadioGroup,
	Stack,
} from '@mui/material';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {
	getAllCategories,
	setIsCreateCategory,
	setNewCategory,
	createCategory,
} from '../../redux/slices/categorySlice';
import {
	setPostType,
	setTitle,
	setContent,
	createPost,
	clearSuccess,
} from '../../redux/slices/postSlice';
import './create-post.scss';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import Section from '../../components/Section';
import TransferList from '../../components/TransferList';

const CreatePost = () => {
	const { isCreateCategory, newCategory } = useSelector(
		(state) => state.category
	);
	const { postType, title, categories, content, selectedPost, success } =
		useSelector((state) => state.post);
	const [videoAddress, setVideoAddress] = useState('');
	const navigate = useNavigate();
	const dispatch = useDispatch();

	const handleChange = (input, value) => {
		const actionMap = {
			postType: setPostType,
			title: setTitle,
			isCreateCategory: setIsCreateCategory,
			category: setNewCategory,
			content: setContent,
		};

		const action = actionMap[input];

		action && dispatch(action(value));
	};

	const handleCreateCategory = () => {
		const data = {
			category: newCategory,
		};
		dispatch(createCategory(data));
	};

	const handleSubmit = (e) => {
		e.preventDefault();
		let newPost;

		if (postType === 'blog') {
			newPost = {
				title,
				categories,
				content,
				postType,
			};
		} else if (postType === 'vlog') {
			newPost = {
				title,
				categories,
				content: 'VLOG',
				media: videoAddress,
				postType,
			};
		}

		dispatch(createPost(newPost));
	};

	const handleLoadCategories = useCallback(() => {
		dispatch(getAllCategories());
	}, [dispatch]);

	const handleSuccess = useCallback(() => {
		if (success) navigate(`/admin/blog/post/${selectedPost?._id}`);
		dispatch(clearSuccess());
	}, [navigate, dispatch, success, selectedPost]);

	useEffect(() => {
		handleLoadCategories();
	}, [handleLoadCategories]);

	useEffect(() => {
		handleSuccess();
	}, [handleSuccess]);

	return (
		<Section id='create-post' maxWidth='xl'>
			<Paper elevation={5}>
				<form className='post-form' onSubmit={handleSubmit}>
					<Button type='submit' variant='contained' className='create-submit'>
						Publish
					</Button>
					<Divider>
						<Chip label='Post Type' size='small' className='divider-chip' />
					</Divider>
					<div className='create-post-type'>
						<FormControl>
							<RadioGroup
								row
								value={postType}
								name='post-type-radio-group'
								onChange={(e) => handleChange('postType', e.target.value)}
							>
								<FormControlLabel
									value='blog'
									control={<Radio />}
									label='Blog'
								/>
								<FormControlLabel
									value='vlog'
									control={<Radio />}
									label='Vlog'
								/>
							</RadioGroup>
						</FormControl>
					</div>
					{postType === 'blog' ? (
						<>
							<Divider>
								<Chip label='Title' size='small' className='divider-chip' />
							</Divider>
							<div className='create-form-group file-and-title'>
								<FormControl>
									<input
										type='text'
										placeholder='Title'
										className='create-input'
										value={title}
										onChange={(e) => handleChange('title', e.target.value)}
										autoFocus
									/>
								</FormControl>
							</div>
							<Divider>
								<Chip
									label='Categories'
									size='small'
									className='divider-chip'
								/>
							</Divider>
							<div className='create-form-group transfer'>
								<FormControl>
									<TransferList />
								</FormControl>
								<Stack direction='row' gap={2} alignItems='center'>
									<FormControlLabel
										control={
											<Checkbox
												checked={isCreateCategory}
												onChange={(e) =>
													handleChange('isCreateCategory', e.target.checked)
												}
											/>
										}
										label='New Category'
									/>
									{isCreateCategory && (
										<Stack direction='row' alignItems='center'>
											<input
												type='text'
												placeholder='New Category'
												className='create-input'
												value={newCategory}
												onChange={(e) =>
													handleChange('category', e.target.value)
												}
											/>
											<IconButton
												disabled={!newCategory}
												className='add-category-btn'
												onClick={handleCreateCategory}
											>
												<AddCircleIcon />
											</IconButton>
										</Stack>
									)}
								</Stack>
							</div>
							<Divider>
								<Chip label='Content' size='small' className='divider-chip' />
							</Divider>
							<div className='create-form-group'>
								<FormControl fullWidth>
									<textarea
										cols='50'
										rows='10'
										placeholder='Tell your story...'
										className='create-input create-text'
										value={content}
										onChange={(e) => handleChange('content', e.target.value)}
									/>
								</FormControl>
							</div>
						</>
					) : (
						postType === 'vlog' && (
							<>
								{videoAddress && (
									<iframe
										title='Vlog Post'
										src={videoAddress}
										frameBorder='0'
										controls='0'
										allow='accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture'
										allowFullScreen
										className='create-img'
									/>
								)}
								<div className='create-form-group vlog-form'>
									<div className='create-video-address-input'>
										<input
											type='text'
											placeholder='Video Address'
											className='create-input'
											onChange={(e) => setVideoAddress(e.target.value)}
										/>
									</div>
									<div className='create-title'>
										<input
											type='text'
											placeholder='Title'
											className='create-input'
											onChange={(e) => handleChange('title', e.target.value)}
											autoFocus
										/>
									</div>
								</div>
								<div className='create-form-group'>
									<select
										className='form-select'
										onChange={(e) => handleChange('category', e.target.value)}
									>
										<option defaultValue>Choose a Category...</option>
									</select>
								</div>
							</>
						)
					)}
				</form>
			</Paper>
		</Section>
	);
};

export default CreatePost;
