import { AppBar, Box, Stack, Toolbar } from '@mui/material';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import User from '../User';

const Topbar = () => {
	const { user } = useSelector((state) => state.user);
	const location = useLocation();

	const path = location.pathname;
	const check = path.split('/')[2];

	return (
		<AppBar
			sx={{
				boxShadow: 'none',
				height: { xs: 56, sm: 64 },
				zIndex: 1101,
				bgcolor: 'lightcoral',
			}}
		>
			<Toolbar sx={{ justifyContent: 'center', px: 2 }}>
				<Box sx={{ flexGrow: 1 }} />
				<Stack direction='row' alignItems='center' spacing={1}>
					{user && check !== 'preferences' && <User type='member' />}
				</Stack>
			</Toolbar>
		</AppBar>
	);
};

export default Topbar;
