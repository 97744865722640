import {
	Avatar,
	Box,
	Divider,
	IconButton,
	MenuItem,
	Popover,
	Typography,
} from '@mui/material';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { setAcctOpen, logout } from '../../redux/slices/userSlice';

const User = ({ type }) => {
	const { user, acctOpen } = useSelector((state) => state.user);
	const [anchorEl, setAnchorEl] = useState(null);

	const navigate = useNavigate();
	const dispatch = useDispatch();

	const handleOpen = (e) => {
		setAnchorEl(e.currentTarget);
		dispatch(setAcctOpen(true));
	};

	const handleClose = () => {
		setAnchorEl(null);
		dispatch(setAcctOpen(false));
	};

	const open = Boolean(anchorEl);

	const handleHome = () => {
		if (type === 'admin') {
			navigate('/admin/dashboard');
		} else if (type === 'member') {
			navigate('/member/home');
		}
		handleClose();
	};

	const handleProfile = () => {
		navigate(`/${type}/profile`);
		handleClose();
	};

	const handleLogout = () => {
		dispatch(logout());
	};

	return (
		<>
			<IconButton
				onClick={handleOpen}
				sx={{
					width: 40,
					height: 40,
					background: 'rbga(171, 171, 171, .08)',
					...(acctOpen && {
						background: 'linear-gradient(135deg, #73BAFB 0%, #1877F2 100%',
					}),
				}}
			>
				<Avatar
					src={user?.profilePic}
					alt={user?.firstName + ' ' + user?.lastName}
					sx={{
						width: 36,
						height: 36,
						border: acctOpen ? '2px ridge dodgerblue' : '2px solid #f7f7f7',
					}}
				>
					{user?.firstName + ' ' + user?.lastName}
				</Avatar>
			</IconButton>
			<Popover
				open={open}
				anchorEl={anchorEl}
				onClose={handleClose}
				anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
				transformOrigin={{ vertical: 'top', horizontal: 'right' }}
				slotProps={{ paper: { sx: { p: 0, mt: 6, ml: 0.75, width: 200 } } }}
			>
				<Box sx={{ my: 1.5, px: 2 }}>
					<Typography variant='subtitle2' noWrap>
						{user?.firstName + ' ' + user?.lastName}
					</Typography>
					<Typography
						variant='body2'
						sx={{ color: 'text.secondary', fontSize: '0.7rem' }}
						noWrap
					>
						{user?.email}
					</Typography>
				</Box>
				<Divider sx={{ borderStyle: 'solid' }} />
				<MenuItem onClick={handleHome}>Home</MenuItem>
				<MenuItem onClick={handleProfile}>Profile</MenuItem>
				<Divider sx={{ borderStyle: 'solid', m: 0 }} />
				<MenuItem
					disableRipple
					disableTouchRipple
					onClick={handleLogout}
					sx={{ typography: 'body2', color: 'error.main', py: 1.5 }}
				>
					Logout
				</MenuItem>
			</Popover>
		</>
	);
};

export default User;
