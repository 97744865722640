import { Paper } from '@mui/material';
import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
	setFirstName,
	setLastName,
	setEmail,
	interested,
	clearErrors,
	clearSuccess,
} from '../../../../redux/slices/userSlice';
import './interest.scss';
import Section from '../../../../components/Section';
import TextInput from '../../../../components/TextInput';
import Button from '../../../../components/Button';

const Interest = () => {
	const { firstName, lastName, email, success, errors } = useSelector(
		(state) => state.user
	);
	const dispatch = useDispatch();

	const handleFocus = () => {
		dispatch(clearErrors());
	};

	const handleChange = (input, value) => {
		const actionMap = {
			first: setFirstName,
			last: setLastName,
			email: setEmail,
		};

		const action = actionMap[input];

		action && dispatch(action(value));
	};

	const handleSubmit = (e) => {
		e.preventDefault();
		const data = {
			firstName,
			lastName,
			email,
			username: email,
		};

		dispatch(interested(data));
	};

	const handleSuccess = useCallback(() => {
		success &&
			setTimeout(() => {
				dispatch(clearSuccess());
			}, 5000);
	}, [success, dispatch]);

	useEffect(() => {
		handleSuccess();
	}, [handleSuccess]);

	return (
		<Section id='interest' maxWidth='lg'>
			<Paper className='interest' elevation={12}>
				<form action=''>
					<div className='name-container'>
						<TextInput
							type='text'
							label='First Name'
							size='small'
							margin='dense'
							value={firstName}
							onFocus={handleFocus}
							onChange={(e) => handleChange('first', e.target.value)}
							error={errors?.firstName}
						/>
						<TextInput
							type='text'
							label='Last Name'
							size='small'
							margin='dense'
							value={lastName}
							onFocus={handleFocus}
							onChange={(e) => handleChange('last', e.target.value)}
							error={errors?.lastName}
						/>
					</div>
					<TextInput
						fullWidth
						type='email'
						label='Email'
						size='small'
						margin='dense'
						value={email}
						onFocus={handleFocus}
						onChange={(e) => handleChange('email', e.target.value)}
						error={errors?.email}
					/>
					<Button label='SUBMIT' btnClass='submit' onClick={handleSubmit} />
				</form>
				<div className='disclose-container'>
					<span>
						By submitting your email address, you consent to receiving email
						updates about our products and services.
					</span>
				</div>
				{success && <h6 className='success'>{success?.message}</h6>}
			</Paper>
		</Section>
	);
};

export default Interest;
