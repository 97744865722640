import {
	createAsyncThunk,
	createEntityAdapter,
	createSlice,
} from '@reduxjs/toolkit';
import { logout } from './userSlice';
import atsApi from '../../api/atsApi';

export const addContent = createAsyncThunk(
	'content/add_content',
	async (data, { rejectWithValue, dispatch }) => {
		try {
			const res = await atsApi.post('/content', data);
			const { success } = res.data;
			if (success) dispatch(retrieveAllContent());
			return success;
		} catch (err) {
			console.log(err);
			return rejectWithValue(err.response.data);
		}
	}
);

export const retrieveAllContent = createAsyncThunk(
	'content/retrieve_all',
	async (data, { rejectWithValue, display }) => {
		try {
			const res = await atsApi.get('/content');
			return res.data;
		} catch (err) {
			console.log(err);
			return rejectWithValue(err.response.data);
		}
	}
);

export const getSingleContet = createAsyncThunk(
	'content/retrieve_single',
	async (data, { rejectWithValue }) => {
		try {
			const res = await atsApi.get(`/content/?id=${data}`);
			return res.data;
		} catch (err) {
			console.log(err);
			return rejectWithValue(err.response.data);
		}
	}
);

export const getContentByCategory = createAsyncThunk(
	'content/retrieve_category',
	async (data, { rejectWithValue }) => {
		try {
			const res = await atsApi.get(`/content/?cat=${data}`);
			return res.data;
		} catch (err) {
			console.log(err);
			return rejectWithValue(err.response.data);
		}
	}
);

export const getContentByTag = createAsyncThunk(
	'content/retrieve_tags',
	async (data, { rejectWithValue }) => {
		try {
			const res = await atsApi.get(`/content/?tag=${data}`);
			return res.data;
		} catch (err) {
			console.log(err);
			return rejectWithValue(err.response.data);
		}
	}
);

export const getArchivedContent = createAsyncThunk(
	'content/retrieve_archived',
	async (data, { rejectWithValue }) => {
		try {
			const res = await atsApi.get(`/content/?archived=true`);
			return res.data;
		} catch (err) {
			console.log(err);
			return rejectWithValue(err.response.data);
		}
	}
);

export const getFeaturedContent = createAsyncThunk(
	'content/retrieve_featured',
	async (data, { rejectWithValue }) => {
		try {
			const res = await atsApi.get('/content/?featured=true');
			return res.data;
		} catch (err) {
			console.log(err);
			return rejectWithValue(err.response.data);
		}
	}
);

export const updateContent = createAsyncThunk();

export const deleteContent = createAsyncThunk();

export const contentAdapter = createEntityAdapter();
const initialState = contentAdapter.getInitialState({
	loading: false,
	type: '',
	category: '',
	title: '',
	url: '',
	cost: '',
	tags: [],
	singleContent: null,
	allContent: null,
	featuredContent: null,
	success: null,
	errors: null,
});

export const contentSlice = createSlice({
	name: 'content',
	initialState,
	reducers: {
		setContentType: (state, action) => {
			state.type = action.payload;
		},
		setCategory: (state, action) => {
			state.category = action.payload;
		},
		setTitle: (state, action) => {
			state.title = action.payload;
		},
		setUrl: (state, action) => {
			state.url = action.payload;
		},
		setCost: (state, action) => {
			state.cost = action.payload;
		},
		setTags: (state, action) => {
			state.tags = state.tags.push(action.payload);
		},
		setSingle: (state, action) => {
			state.singleContent = action.payload;
		},
		clearSuccess: (state) => {
			state.success = null;
		},
		clearErrors: (state) => {
			state.errors = null;
		},
	},
	extraReducers: (builder) => {
		builder
			.addCase(addContent.pending, (state) => {
				state.loading = true;
				state.errors = null;
			})
			.addCase(addContent.fulfilled, (state, action) => {
				state.loading = false;
				state.success = action.payload;
			})
			.addCase(addContent.rejected, (state, action) => {
				state.loading = false;
				state.errors = action.payload;
			})
			.addCase(retrieveAllContent.pending, (state) => {
				state.loading = true;
				state.errors = null;
			})
			.addCase(retrieveAllContent.fulfilled, (state, action) => {
				state.loading = false;
				state.allContent = action.payload;
			})
			.addCase(retrieveAllContent.rejected, (state, action) => {
				state.loading = false;
				state.errors = action.payload;
			})
			.addCase(getFeaturedContent.pending, (state) => {
				state.loading = true;
				state.errors = null;
			})
			.addCase(getFeaturedContent.fulfilled, (state, action) => {
				state.loading = false;
				state.featuredContent = action.payload;
			})
			.addCase(getFeaturedContent.rejected, (state, action) => {
				state.loading = false;
				state.errors = action.payload;
			})
			.addCase(logout, (state) => {
				state.loading = false;
				state.type = '';
				state.category = '';
				state.title = '';
				state.url = '';
				state.cost = '';
				state.tags = [];
				state.singleContent = null;
				state.allContent = null;
				state.featuredContent = null;
				state.success = null;
				state.errors = null;
				contentAdapter.removeAll(state);
			});
	},
});

export const {
	setContentType,
	setCategory,
	setTitle,
	setUrl,
	setCost,
	setTags,
	setSingle,
	clearSuccess,
	clearErrors,
} = contentSlice.actions;

export default contentSlice.reducer;
