import { Box, Drawer } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { setDrawerOpen } from '../../../../redux/slices/userSlice';
import { NAV } from '../../../../util/data';
import NavContent from './components/NavContent';

const SideNav = ({ type }) => {
	const { isMobile } = useSelector((state) => state.app);
	const { drawerOpen } = useSelector((state) => state.user);
	const dispatch = useDispatch();

	const handleClose = () => {
		dispatch(setDrawerOpen(false));
	};

	return (
		<Box sx={{ flexShrink: { lg: 0 }, width: { lg: NAV.WIDTH } }}>
			{isMobile ? (
				<Drawer
					open={drawerOpen}
					onClose={handleClose}
					slotProps={{ paper: { sx: { width: NAV.WIDTH } } }}
				>
					<NavContent type={type} />
				</Drawer>
			) : (
				<Box
					sx={{
						height: 1,
						position: 'fixed',
						width: NAV.WIDTH,
						borderRight: '1px solid rgba(171, 171, 171, .2)',
					}}
				>
					<NavContent type={type} />
				</Box>
			)}
		</Box>
	);
};

export default SideNav;
