import { Button, Stack, Typography } from '@mui/material';
import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setContentDialog } from '../../redux/slices/appSlice';
import { retrieveAllContent } from '../../redux/slices/contentSlice';
import AddIcon from '@mui/icons-material/Add';
import './catalog.scss';
import Section from '../../components/Section';
import CatalogItem from '../../components/CatalogItem';
import ContentDialog from './components/ContentDialog';

const Catalog = () => {
	const { allContent } = useSelector((state) => state.content);
	const dispatch = useDispatch();

	const handleContentDialog = () => {
		dispatch(setContentDialog(true));
	};

	const loadContent = useCallback(() => {
		dispatch(retrieveAllContent());
	}, [dispatch]);

	useEffect(() => {
		loadContent();
	}, [loadContent]);

	return (
		<Section id='catalog-view' maxWidth='xl'>
			<Stack
				direction='row'
				alignItems='center'
				justifyContent='space-between'
				mb={2}
			>
				<Typography variant='h5'>Catalog</Typography>
				<Button
					variant='contained'
					color='inherit'
					startIcon={<AddIcon />}
					onClick={handleContentDialog}
				>
					New Content
				</Button>
			</Stack>
			<div id='catalog'>
				{allContent?.map((item) => (
					<CatalogItem key={item._id} data={item} />
				))}
			</div>
			<ContentDialog />
		</Section>
	);
};

export default Catalog;
