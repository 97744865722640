import { useNavigate } from 'react-router-dom';
import './bio.scss';
import Section from '../../../../../../components/Section';
import Button from '../../../../../../components/Button';

const Bio = () => {
	const navigate = useNavigate();

	const handleClick = () => {
		navigate('/about-me');
	};

	return (
		<Section id='bio' maxWidth='lg'>
			<div className='bio-container'>
				<div className='info-container'>
					<h1>Meet Your Coach</h1>
					<p>
						Get to know your Coach and Founder of Aligned to Shine, Katie Budaj!
					</p>

					<Button
						label='Meet Katie!'
						btnClass='lnk-btn'
						onClick={handleClick}
					/>
				</div>
				<div className='pic-container'>
					<img src='/katie-temp.png' alt='' />
				</div>
			</div>
		</Section>
	);
};

export default Bio;
