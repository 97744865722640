import {
	Avatar,
	Box,
	Checkbox,
	IconButton,
	MenuItem,
	Popover,
	Stack,
	TableCell,
	TableRow,
	Typography,
} from '@mui/material';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import {
	openDelete,
	setDeleteData,
} from '../../../../../../redux/slices/appSlice';
import { deleteUser } from '../../../../../../redux/slices/userSlice';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import dayjs from 'dayjs';
import './row.scss';

const UserTableRow = ({
	selected,
	userId,
	displayName,
	avatarUrl,
	email,
	username,
	createdAt,
	optInEmail,
	optInSms,
	optInVoice,
	handleClick,
}) => {
	const [open, setOpen] = useState(null);
	const dispatch = useDispatch();

	const handleOpenMenu = (e) => {
		setOpen(e.currentTarget);
	};

	const handleCloseMenu = () => {
		setOpen(null);
	};

	const handleDelete = () => {
		const data = {
			type: 'user',
			action: deleteUser(userId),
		};
		dispatch(setDeleteData(data));
		dispatch(openDelete(true));
	};

	return (
		<>
			<TableRow hover tabIndex={-1} role='checkbox' selected={selected}>
				<TableCell padding='checkbox'>
					<Checkbox disableRipple checked={selected} onChange={handleClick} />
				</TableCell>
				<TableCell component='th' scope='row' padding='none'>
					<Stack direction='row' alignItems='center' spacing={2}>
						<Avatar alt={displayName} src={avatarUrl} />
						<Typography variant='subtitle2' noWrap>
							{displayName}
						</Typography>
					</Stack>
				</TableCell>
				<TableCell>{email}</TableCell>
				<TableCell>{username}</TableCell>
				<TableCell align='center'>
					{dayjs(new Date(createdAt)).format('MM-DD-YYYY')}
				</TableCell>
				<TableCell align='center'>
					<Box
						className={
							optInEmail ? 'opt-in-label positive' : 'opt-in-label negative'
						}
					>
						{optInEmail ? 'Yes' : 'No'}
					</Box>
				</TableCell>
				<TableCell align='center'>
					<Box
						className={
							optInSms ? 'opt-in-label positive' : 'opt-in-label negative'
						}
					>
						{optInSms ? 'Yes' : 'No'}
					</Box>
				</TableCell>
				<TableCell align='center'>
					<Box
						className={
							optInVoice ? 'opt-in-label positive' : 'opt-in-label negative'
						}
					>
						{optInVoice ? 'Yes' : 'No'}
					</Box>
				</TableCell>
				<TableCell align='right'>
					<IconButton onClick={handleOpenMenu}>
						<MoreVertIcon />
					</IconButton>
				</TableCell>
			</TableRow>
			<Popover
				open={!!open}
				anchorEl={open}
				onClose={handleCloseMenu}
				anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
				transformOrigin={{ vertical: 'top', horizontal: 'right' }}
				slotProps={{ paper: { sx: { width: 140 } } }}
			>
				<MenuItem onClick={handleCloseMenu}>
					<EditIcon sx={{ mr: 2 }} />
					Edit
				</MenuItem>
				<MenuItem onClick={handleDelete} sx={{ color: 'error.main' }}>
					<DeleteIcon sx={{ mr: 2 }} />
					Delete
				</MenuItem>
			</Popover>
		</>
	);
};

export default UserTableRow;
