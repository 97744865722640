import './footer.scss';

const Footer = () => {
	return (
		<footer>
			<div className='copyright'>© Aligned To Shine</div>
			<div className='links'>
				<a href='#!'>Account</a>
				<a href='#!'>Privacy Policy</a>
				<a href='#!'>Terms of Service</a>
				<a href='#!'>Website By digitalkatana</a>
			</div>
		</footer>
	);
};

export default Footer;
