import { Paper } from '@mui/material';
import { useSelector } from 'react-redux';
import './businesscard.scss';
import QrGenerator from './components/QrGenerator';

const BusinessCard = () => {
	const { theme } = useSelector((state) => state.app);

	return (
		<div id='business-card'>
			<Paper
				className={theme === 'dark' ? 'canvas dark' : 'canvas'}
				elevation={12}
			>
				<div className='info-box'>
					<h2 className='name'>Katie Budaj</h2>
					<h3 className='info'>Founder</h3>
					<h4 className='info'>Aligned To Shine</h4>
					<h5 className='info'>katie@alignedtoshine.org</h5>
					{/* <h5 className='info'>Mobile: (858) 449-9212</h5> */}
				</div>
				<img src='/head-logo.png' alt='' className='img-divider' />
				<div className='qr-box'>
					<img src='/head-logo.png' alt='' />
					<QrGenerator />
					{/* <a href='https://drive.google.com/file/d/1TZkDpQtuYROXtLe_jvCB9bztnUOKMaTo/view?usp=drivesdk'>
						V-Card
					</a> */}
				</div>
			</Paper>
		</div>
	);
};

export default BusinessCard;
