import {
	Alert,
	Container,
	FormControl,
	FormControlLabel,
	FormLabel,
	Grid,
	Radio,
	RadioGroup,
	Paper,
	Snackbar,
} from '@mui/material';
import { useCallback, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { radioValueHandler } from '../../../../util/helpers';
import atsApi from '../../../../api/atsApi';
import './preferences.scss';
import Topbar from '../../../../components/Topbar';
import Spacer from '../../../../components/Spacer';
import Button from '../../../../components/Button';

const Preferences = () => {
	const [open, setOpen] = useState(false);
	const [user, setUser] = useState(null);
	const [communication, setCommunication] = useState({
		email: true,
		sms: false,
		voice: false,
		snail: false,
	});
	const [success, setSuccess] = useState(null);
	const location = useLocation();

	const path = location.pathname;
	const mid = path.split('/')[4];

	const handleChange = (input, value) => {
		setCommunication((prevComm) => ({
			...prevComm,
			[input]: radioValueHandler(value),
		}));
	};

	const handleSubmit = async (e) => {
		e.preventDefault();
		const data = {
			_id: user?._id,
			...(communication !== user.communication && { communication }),
		};

		try {
			const res = await atsApi.put(`/users/${data._id}/preferences`, data);
			setSuccess(res.data.success);
			setUser(res.data.updated);
			setCommunication(res.data.updated.communication);
		} catch (err) {
			console.log(err);
		}
	};

	const handleClose = () => {
		setOpen(false);
		setSuccess(null);
	};

	const handleLoadUser = useCallback(async () => {
		try {
			const res = await atsApi.get(`/users/?id=${mid}`);
			setUser(res.data);
			setCommunication(res.data.communication);
		} catch (err) {
			console.log(err);
		}
	}, [mid]);

	useEffect(() => {
		handleLoadUser();
	}, [handleLoadUser]);

	useEffect(() => {
		if (success) {
			setOpen(true);
		}
	}, [success]);

	return (
		<>
			<Topbar />
			<Spacer />
			<Container maxWidth='lg'>
				<h1>Hello, {user?.firstName}!</h1>
				<Grid container spacing={2}>
					<Grid item>
						<Paper className='canvas' elevation={10}>
							<form onSubmit={handleSubmit}>
								<div className='comm-container'>
									<h4>Communications</h4>
									<FormControl>
										<FormLabel id='comm-group'>Email</FormLabel>
										<RadioGroup
											aria-labelledby='comm-group'
											value={communication.email}
											onChange={(e) => handleChange('email', e.target.value)}
										>
											<FormControlLabel
												value={'true'}
												control={<Radio />}
												label='Opt-In'
											/>
											<FormControlLabel
												value={'false'}
												control={<Radio />}
												label='Opt-Out'
											/>
										</RadioGroup>
									</FormControl>
								</div>
								<Button type='submit' label='SUBMIT' btnClass='submit-btn' />
							</form>
							<Snackbar
								open={open}
								autoHideDuration={7000}
								onClose={handleClose}
							>
								<Alert severity='success'>{success?.message}</Alert>
							</Snackbar>
						</Paper>
					</Grid>
				</Grid>
			</Container>
		</>
	);
};

export default Preferences;
