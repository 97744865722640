import {
	createAsyncThunk,
	createEntityAdapter,
	createSlice,
} from '@reduxjs/toolkit';
// import { PURGE } from 'redux-persist';
import { openDelete, setDeleteData } from './appSlice';
import { getAllCategories } from './categorySlice';
import { getFeaturedContent } from './contentSlice';
import { socket } from '../../util/socket';
import { radioValueHandler } from '../../util/helpers';
import atsApi from '../../api/atsApi';

export const interested = createAsyncThunk(
	'user/interested',
	async (data, { rejectWithValue }) => {
		try {
			const res = await atsApi.post('/users/register', data);
			return res.data;
		} catch (err) {
			console.log(err);
			return rejectWithValue(err.response.data);
		}
	}
);

export const register = createAsyncThunk(
	'user/register',
	async (data, { rejectWithValue, dispatch }) => {
		try {
			const res = await atsApi.post('/users/register', data);
			const { userData, token } = res.data;
			localStorage.setItem('token', token);
			if (userData.role === 'user') {
				dispatch(getFeaturedContent());
			}
			return userData;
		} catch (err) {
			console.log(err);
			return rejectWithValue(err.response.data);
		}
	}
);

export const userLogin = createAsyncThunk(
	'user/login',
	async (data, { rejectWithValue, dispatch }) => {
		try {
			const res = await atsApi.post('/users/login', data);
			const { userData, token } = res.data;
			localStorage.setItem('token', token);
			if (userData) dispatch(getAllCategories());
			if (userData.role === 'user') {
				dispatch(getFeaturedContent());
			}
			return userData;
		} catch (err) {
			console.log(err);
			return rejectWithValue(err.response.data);
		}
	}
);

export const generatePasswordToken = createAsyncThunk(
	'user/generate_password_token',
	async (data, { rejectWithValue }) => {
		try {
			const res = await atsApi.post('/users/forgot-password-token', data);
			return res.data;
		} catch (err) {
			console.log(err);
			return rejectWithValue(err.response.data);
		}
	}
);

export const resetPasswordWithToken = createAsyncThunk(
	'user/reset_with_token',
	async (data, { rejectWithValue }) => {
		try {
			const res = await atsApi.post('/users/reset-password', data);
			return res.data;
		} catch (err) {
			console.log(err);
			return rejectWithValue(err.response.data);
		}
	}
);

export const getUsers = createAsyncThunk(
	'user/get_users',
	async (data, { rejectWithValue }) => {
		try {
			const res = await atsApi.get('/users');
			return res.data;
		} catch (err) {
			console.log(err);
			return rejectWithValue(err.response.data);
		}
	}
);

export const getUser = createAsyncThunk(
	'user/get_user',
	async (data, { rejectWithValue, dispatch }) => {
		try {
			const res = await atsApi.get(`/users/?id=${data}`);
			return res.data;
		} catch (err) {
			console.log(err);
			return rejectWithValue(err.response.data);
		}
	}
);

export const addUser = createAsyncThunk(
	'user/add_user',
	async (data, { rejectWithValue, dispatch }) => {
		try {
			const res = await atsApi.post('/users/add', data);
			const { success } = res.data;
			if (success) {
				dispatch(getUsers());
			}
			return success;
		} catch (err) {
			return rejectWithValue(err.response.data);
		}
	}
);

export const findAndAuth = createAsyncThunk(
	'user/fimd_and_auth',
	async (data, { rejectWithValue, dispatch }) => {
		try {
			const userData = await atsApi.get(`/users/?id=${data}`);
			const user = userData.data;
			const loginData = {
				login: user.email,
			};
			console.log(loginData);
			loginData && dispatch(userLogin(loginData));
			return user;
		} catch (err) {
			console.log(err);
			return rejectWithValue(err.response.data);
		}
	}
);

export const updateUser = createAsyncThunk(
	'user/update_user',
	async (data, { rejectWithValue }) => {
		try {
			const res = await atsApi.put(`/users/${data._id}`, data);
			return res.data;
		} catch (err) {
			return rejectWithValue(err.response.data);
		}
	}
);

export const deleteUser = createAsyncThunk(
	'user/delete_user',
	async (data, { rejectWithValue, dispatch }) => {
		try {
			const res = await atsApi.delete(`/users/${data}/delete`);
			const { success } = res.data;
			if (success) {
				dispatch(getUsers());
				dispatch(openDelete(false));
				dispatch(setDeleteData(null));
			}
			return success;
		} catch (err) {
			return rejectWithValue(err.response.data);
		}
	}
);

export const userAdapter = createEntityAdapter();
const initialState = userAdapter.getInitialState({
	loading: false,
	dialogOpen: false,
	acctOpen: false,
	notificationOpen: false,
	languageOpen: false,
	drawerOpen: false,
	authType: 'login',
	login: '',
	firstName: '',
	lastName: '',
	username: '',
	email: '',
	password: '',
	confirmPassword: '',
	phone: {
		mobile: '',
		home: '',
		work: '',
	},
	communication: {
		email: true,
		sms: false,
		voice: false,
		snail: false,
	},
	user: null,
	allUsers: null,
	success: null,
	errors: null,
});

export const userSlice = createSlice({
	name: 'user',
	initialState,
	reducers: {
		setDialogOpen: (state, action) => {
			state.dialogOpen = action.payload;
		},
		setAcctOpen: (state, action) => {
			state.acctOpen = action.payload;
		},
		setNotificationOpen: (state, action) => {
			state.notificationOpen = action.payload;
		},
		setLanguageOpen: (state, action) => {
			state.languageOpen = action.payload;
		},
		setDrawerOpen: (state, action) => {
			state.drawerOpen = action.payload;
		},
		setAuthType: (state, action) => {
			state.authType = action.payload;
			state.login = '';
			state.firstName = '';
			state.lastName = '';
			state.username = '';
			state.email = '';
			state.password = '';
			state.confirmPassword = '';
			state.errors = null;
		},
		setLogin: (state, action) => {
			state.login = action.payload;
		},
		setFirstName: (state, action) => {
			state.firstName = action.payload;
		},
		setLastName: (state, action) => {
			state.lastName = action.payload;
		},
		setUsername: (state, action) => {
			state.username = action.payload;
		},
		setEmail: (state, action) => {
			state.email = action.payload;
		},
		setPassword: (state, action) => {
			state.password = action.payload;
		},
		setConfirmPassword: (state, action) => {
			state.confirmPassword = action.payload;
		},
		setMobilePhone: (state, action) => {
			state.phone.mobile = action.payload;
		},
		setHomePhone: (state, action) => {
			state.phone.home = action.payload;
		},
		setWorkPhone: (state, action) => {
			state.phone.work = action.payload;
		},
		setEmailComm: (state, action) => {
			state.communication.email = radioValueHandler(action.payload);
		},
		setSmsComm: (state, action) => {
			state.communication.sms = radioValueHandler(action.payload);
		},
		setVoiceComm: (state, action) => {
			state.communication.voice = radioValueHandler(action.payload);
		},
		setSnailComm: (state, action) => {
			state.communication.snail = radioValueHandler(action.payload);
		},
		populateUser: (state) => {
			state.firstName = state.user.firstName;
			state.lastName = state.user.lastName;
			state.username = state.user.username;
			state.email = state.user.email;
			state.phone = state.user.phone;
			state.communication = state.user.communication;
		},
		clearUser: (state) => {
			state.firstName = '';
			state.lastName = '';
			state.username = '';
			state.email = '';
			state.phone = {
				mobile: '',
				home: '',
				work: '',
			};
			state.communication = {
				email: true,
				sms: false,
				voice: false,
				snail: false,
			};
		},
		clearSuccess: (state) => {
			state.success = null;
		},
		clearErrors: (state) => {
			state.errors = null;
		},
		logout: (state) => {
			localStorage.removeItem('token');
			state.loading = false;
			state.acctOpen = false;
			state.notificationOpen = false;
			state.languageOpen = false;
			state.drawerOpen = false;
			state.authType = 'login';
			state.login = '';
			state.firstName = '';
			state.lastName = '';
			state.username = '';
			state.email = '';
			state.password = '';
			state.confirmPassword = '';
			state.phone = {
				mobile: '',
				home: '',
				work: '',
			};
			state.communication = {
				email: true,
				sms: false,
				voice: false,
				snail: false,
			};
			state.user = null;
			state.allUsers = null;
			state.success = null;
			state.errors = null;
			userAdapter.removeAll(state);
		},
	},
	extraReducers: (builder) => {
		builder
			.addCase(interested.pending, (state) => {
				state.loading = true;
				state.errors = null;
			})
			.addCase(interested.fulfilled, (state, action) => {
				state.loading = false;
				state.success = action.payload.success;
				socket.emit('newInterest', action.payload.userData);
				state.firstName = '';
				state.lastName = '';
				state.email = '';
			})
			.addCase(interested.rejected, (state, action) => {
				state.loading = false;
				state.errors = action.payload;
			})
			.addCase(register.pending, (state) => {
				state.loading = true;
				state.errors = null;
			})
			.addCase(register.fulfilled, (state, action) => {
				state.loading = false;
				state.user = action.payload;
				socket.emit('setup', action.payload);
				state.firstName = '';
				state.lastName = '';
				state.username = '';
				state.email = '';
				state.password = '';
				state.confirmPassword = '';
			})
			.addCase(register.rejected, (state, action) => {
				state.loading = false;
				state.errors = action.payload;
			})
			.addCase(userLogin.pending, (state) => {
				state.loading = true;
				state.errors = null;
			})
			.addCase(userLogin.fulfilled, (state, action) => {
				state.loading = false;
				state.user = action.payload;
				socket.emit('setup', action.payload);
				state.login = '';
				state.email = '';
				state.password = '';
			})
			.addCase(userLogin.rejected, (state, action) => {
				state.loading = false;
				state.errors = action.payload;
			})
			.addCase(generatePasswordToken.pending, (state) => {
				state.loading = true;
				state.errors = null;
			})
			.addCase(generatePasswordToken.fulfilled, (state, action) => {
				state.loading = false;
				state.success = action.payload;
				state.email = '';
			})
			.addCase(generatePasswordToken.rejected, (state, action) => {
				state.loading = false;
				state.errors = action.payload;
			})
			.addCase(resetPasswordWithToken.pending, (state) => {
				state.loading = true;
				state.errors = null;
			})
			.addCase(resetPasswordWithToken.fulfilled, (state, action) => {
				state.loading = false;
				state.success = action.payload;
				state.password = '';
			})
			.addCase(resetPasswordWithToken.rejected, (state, action) => {
				state.loading = false;
				state.errors = action.payload;
			})
			.addCase(getUsers.pending, (state) => {
				state.loading = true;
				state.errors = null;
			})
			.addCase(getUsers.fulfilled, (state, action) => {
				state.loading = false;
				state.allUsers = action.payload;
			})
			.addCase(getUsers.rejected, (state, action) => {
				state.loading = false;
				state.errors = action.payload;
			})
			.addCase(getUser.pending, (state) => {
				state.loading = true;
				state.errors = null;
			})
			.addCase(getUser.fulfilled, (state, action) => {
				state.loading = false;
				state.user = action.payload;
				state.firstName = action.payload.firstName;
				state.lastName = action.payload.lastName;
				state.username = action.payload.username;
				state.email = action.payload.email;
				state.communication = action.payload.communication;
			})
			.addCase(getUser.rejected, (state, action) => {
				state.loading = false;
				state.errors = action.payload;
			})
			.addCase(addUser.pending, (state) => {
				state.loading = true;
				state.errors = false;
			})
			.addCase(addUser.fulfilled, (state, action) => {
				state.loading = false;
				state.success = action.payload;
				state.dialogOpen = false;
				state.firstName = '';
				state.lastName = '';
				state.email = '';
			})
			.addCase(addUser.rejected, (state, action) => {
				state.loading = false;
				state.errors = action.payload;
			})
			.addCase(updateUser.pending, (state) => {
				state.loading = true;
				state.errors = null;
			})
			.addCase(updateUser.fulfilled, (state, action) => {
				state.loading = false;
				state.success = action.payload.success;
				state.user = action.payload.updated;
				state.firstName = '';
				state.lastName = '';
				state.username = '';
				state.email = '';
				state.phone = action.payload.updated.phone;
				state.communication = action.payload.updated.communication;
			})
			.addCase(updateUser.rejected, (state, action) => {
				state.loading = false;
				state.errors = action.payload;
			})
			.addCase(findAndAuth.pending, (state) => {
				state.loading = true;
				state.errors = null;
			})
			.addCase(findAndAuth.fulfilled, (state, action) => {
				state.loading = false;
				state.user = action.payload;
				state.firstName = action.payload.firstName;
				state.lastName = action.payload.lastName;
				state.username = action.payload.username;
				state.email = action.payload.email;
				state.communication = action.payload.communication;
			})
			.addCase(findAndAuth.rejected, (state, action) => {
				state.loading = false;
				state.errors = action.payload;
			})
			.addCase(deleteUser.pending, (state) => {
				state.loading = true;
				state.errors = null;
			})
			.addCase(deleteUser.fulfilled, (state, action) => {
				state.loading = false;
				state.success = action.payload;
			})
			.addCase(deleteUser.rejected, (state, action) => {
				state.loading = false;
				state.errors = action.payload;
			});
	},
});

export const {
	setDialogOpen,
	setAcctOpen,
	setNotificationOpen,
	setLanguageOpen,
	setDrawerOpen,
	setAuthType,
	setLogin,
	setFirstName,
	setLastName,
	setUsername,
	setEmail,
	setPassword,
	setConfirmPassword,
	setMobilePhone,
	setHomePhone,
	setWorkPhone,
	setEmailComm,
	setSmsComm,
	setVoiceComm,
	setSnailComm,
	populateUser,
	clearUser,
	clearSuccess,
	clearErrors,
	logout,
} = userSlice.actions;

export default userSlice.reducer;
