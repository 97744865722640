import { Container } from '@mui/material';
import './section.scss';

const Section = ({
	id,
	className,
	style,
	maxWidth,
	containerStyle,
	sx,
	containerClass,
	children,
}) => {
	return (
		<section id={id} className={className} style={style}>
			<Container
				maxWidth={maxWidth}
				style={containerStyle}
				sx={sx}
				className={containerClass}
			>
				{children}
			</Container>
		</section>
	);
};

export default Section;
