import {
	Button,
	Paper,
	Stack,
	Table,
	TableBody,
	TableContainer,
	TablePagination,
	Typography,
} from '@mui/material';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setDialogOpen, getUsers } from '../../../../redux/slices/userSlice';
import {
	emptyRows,
	applyFilter,
	getComparator,
	userRows,
} from '../../../../util/helpers';
import AddIcon from '@mui/icons-material/Add';
import UserTableToolbar from './components/UserTableToolbar';
import UserTableHead from './components/UserTableHead';
import UserTableRow from './components/UserTableRow';
import TableEmptyRows from './components/TableEmptyRows';
import TableNoData from './components/TableNoData';
import './users.scss';
import Section from '../../../../components/Section';
import AddUserDialog from '../../../../components/AddUserDialog';

const Users = () => {
	const { theme } = useSelector((state) => state.app);
	const { loading, user, allUsers } = useSelector((state) => state.user);
	const [subs, setSubs] = useState([]);
	const [page, setPage] = useState(0);
	const [order, setOrder] = useState('asc');
	const [selected, setSelected] = useState([]);
	const [orderBy, setOrderBy] = useState('displayName');
	const [filterName, setFilterName] = useState('');
	const [rowsPerPage, setRowsPerPage] = useState(5);
	const dispatch = useDispatch();

	const handleOpenDialog = () => {
		dispatch(setDialogOpen(true));
	};

	const handleSort = (e, id) => {
		const isAsc = orderBy === id && order === 'asc';
		if (id !== '') {
			setOrder(isAsc ? 'desc' : 'asc');
			setOrderBy(id);
		}
	};

	const handleSelectAllClick = (e) => {
		if (e.target.checked) {
			const newSelecteds = subs.map((n) => n.displayName);
			setSelected(newSelecteds);
			return;
		}
		setSelected([]);
	};

	const handleClick = (e, name) => {
		const selectedIndex = selected.indexOf(name);
		let newSelected = [];
		if (selectedIndex === -1) {
			newSelected = newSelected.concat(selected, name);
		} else if (selectedIndex === 0) {
			newSelected = newSelected.concat(selected.slice(1));
		} else if (selectedIndex === selected.length - 1) {
			newSelected = newSelected.concat(selected.slice(0, -1));
		} else if (selectedIndex > 0) {
			newSelected = newSelected.concat(
				selected.slice(0, selectedIndex),
				selected.slice(selectedIndex + 1)
			);
		}
		setSelected(newSelected);
	};

	const handleChangePage = (e, newPage) => {
		setPage(newPage);
	};

	const handleChangeRowsPerPage = (e) => {
		setPage(0);
		setRowsPerPage(parseInt(e.target.value, 10));
	};

	const handleFilterByName = (e) => {
		setPage(0);
		setFilterName(e.target.value);
	};

	const dataFiltered = applyFilter({
		inputData: subs,
		comparator: getComparator(order, orderBy),
		filterName,
	});

	const notFound = !dataFiltered.length && !!filterName;

	const loadUsers = useCallback(() => {
		dispatch(getUsers());
	}, [dispatch]);

	const loadSubs = useCallback(() => {
		allUsers && setSubs(userRows(allUsers, user));
	}, [allUsers, user]);

	useEffect(() => {
		loadUsers();
	}, [loadUsers]);

	useEffect(() => {
		loadSubs();
	}, [loadSubs]);

	return (
		<Section id='user-view' maxWidth='xl'>
			<Stack
				direction='row'
				alignItems='center'
				justifyContent='space-between'
				mb={2}
			>
				<Typography variant='h5'>Users</Typography>
				<Button
					variant='contained'
					color='inherit'
					startIcon={<AddIcon />}
					onClick={handleOpenDialog}
				>
					New User
				</Button>
			</Stack>
			<div id='table-container'>
				{loading && <h2>Hold Please...</h2>}
				<Paper
					id='users-table'
					className={theme === 'dark' ? theme : ''}
					elevation={10}
				>
					<UserTableToolbar
						numSelected={selected.length}
						filterName={filterName}
						onFilterName={handleFilterByName}
					/>
					<TableContainer>
						<Table
							sx={{
								minWidth: 650,
								maxWidth: 800,
							}}
						>
							<UserTableHead
								order={order}
								orderBy={orderBy}
								rowCount={subs?.length}
								numSelected={selected.length}
								onRequestSort={handleSort}
								onSelectAllClick={handleSelectAllClick}
								headLabel={[
									{ id: 'displayName', label: 'Name' },
									{ id: 'email', label: 'Email' },
									{ id: 'username', label: 'Username' },
									{
										id: 'createdAt',
										label: 'Date Added',
										align: 'center',
										minWidth: 140,
									},
									{
										id: 'optIn:email',
										label: 'Opt In: Email',
										align: 'center',
										minWidth: 140,
									},
									{
										id: 'optIn:sms',
										label: 'Opt In: SMS',
										align: 'center',
										minWidth: 140,
									},
									{
										id: 'optIn:voice',
										label: 'Opt In: Voice',
										align: 'center',
										minWidth: 140,
									},
									{ id: '' },
								]}
							/>
							<TableBody>
								{dataFiltered
									.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
									.map((row) => (
										<UserTableRow
											key={row._id}
											userId={row._id}
											displayName={row.displayName}
											avatarUrl={row.avatarUrl}
											email={row.email}
											username={row.username}
											createdAt={row.createdAt}
											optInEmail={row.optInEmail}
											optInSms={row.optInSms}
											optInVoice={row.optInVoice}
											selected={selected.indexOf(row.displayName) !== -1}
											handleClick={(e) => handleClick(e, row.displayName)}
										/>
									))}
								<TableEmptyRows
									height={77}
									emptyRows={emptyRows(page, rowsPerPage, subs.length)}
								/>
								{notFound && <TableNoData query={filterName} />}
							</TableBody>
						</Table>
					</TableContainer>
					<TablePagination
						page={page}
						component='div'
						count={subs.length}
						rowsPerPage={rowsPerPage}
						onPageChange={handleChangePage}
						rowsPerPageOptions={[5, 10, 25]}
						onRowsPerPageChange={handleChangeRowsPerPage}
					/>
				</Paper>
			</div>
			<AddUserDialog />
		</Section>
	);
};

export default Users;
