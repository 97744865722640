import { AppBar, Toolbar } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import './coming.scss';
import Button from '../../components/Button';
import Spacer from '../../components/Spacer';
import Interest from '../Home/components/Interest';

const ComingSoon = () => {
	const navigate = useNavigate();

	const handleClick = () => {
		navigate('/login');
	};

	return (
		<div id='coming'>
			<AppBar
				sx={{
					boxShadow: 'none',
					height: { xs: 56, sm: 64 },
					zIndex: 1101,
					bgcolor: '#f7f7f7',
				}}
			>
				<Toolbar sx={{ justifyContent: 'center', px: 2 }}>
					<Button
						label='Member Login'
						btnClass='lnk-btn'
						onClick={handleClick}
					/>
				</Toolbar>
			</AppBar>
			<Spacer />
			<h2>We're under construction.</h2>
			<h5>
				For more information/updates, please enter your information below.
			</h5>
			{/* <Section id='interest'>
				<InterestForm />
			</Section> */}
			<Interest />
		</div>
	);
};

export default ComingSoon;
