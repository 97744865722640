import './social.scss';
import Section from '../../../../../../components/Section';
import Button from '../../../../../../components/Button';

const Social = () => {
	const handleClick = () => {
		window.open('https://www.instagram.com/aligned_to_shine', '_blank');
	};

	return (
		<Section id='social' maxWidth='lg'>
			<div className='social-container'>
				<div className='pic-container'>
					<img
						// src='https://kajabi-storefronts-production.kajabi-cdn.com/kajabi-storefronts-production/file-uploads/themes/2150652960/settings_images/3ea5345-405-814-7dd5-d381ab7d513e_937b0660-b93f-41ee-8a72-bc31698e8529.png'
						src='/sunrise.jpg'
						alt=''
					/>
				</div>
				<div className='info-container'>
					<h2>Aligned to Shine IG</h2>
					<p>
						Follow Aligned to Shine on Instagram! Be sure to share with friends
						and family!
					</p>

					<Button label='Aligned IG' btnClass='lnk-btn' onClick={handleClick} />
				</div>
			</div>
		</Section>
	);
};

export default Social;
