import {
	Card,
	CardContent,
	FormControl,
	IconButton,
	InputAdornment,
	Stack,
	TextField,
} from '@mui/material';
import { useCallback, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
	setPassword,
	resetPasswordWithToken,
	clearSuccess,
	clearErrors,
} from '../../redux/slices/userSlice';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import LockResetIcon from '@mui/icons-material/LockReset';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import './reset.scss';
import Button from '../../components/Button';

const ResetPassword = () => {
	const { loading, password, success, errors } = useSelector(
		(state) => state.user
	);
	const [show, setShow] = useState(false);
	const location = useLocation();
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const path = location.pathname.split('/')[2];

	const handleFocus = () => {
		dispatch(clearErrors());
	};

	const handleChange = (e) => {
		dispatch(setPassword(e.target.value));
	};

	const handleSubmit = (e) => {
		e.preventDefault();

		const data = {
			token: path,
			password,
		};

		dispatch(resetPasswordWithToken(data));
	};

	const handleSuccess = useCallback(() => {
		success &&
			setTimeout(() => {
				dispatch(clearSuccess());
				navigate('/login');
			}, 7000);
	}, [success, dispatch, navigate]);

	useEffect(() => {
		handleSuccess();
	}, [handleSuccess]);

	return (
		<div id='reset'>
			<Card id='reset-password' elevation={12}>
				<CardContent className='container'>
					<Stack alignItems='center'>
						<h2>Reset Password</h2>
						<LockResetIcon className='icon' fontSize='large' />
					</Stack>
					<form onSubmit={handleSubmit}>
						<FormControl variant='standard'>
							<TextField
								type={show ? 'text' : 'password'}
								label='Password'
								size='small'
								margin='dense'
								value={password}
								onFocus={handleFocus}
								onChange={handleChange}
								InputProps={{
									endAdornment: (
										<InputAdornment position='end'>
											<IconButton
												onClick={() => setShow(!show)}
												onMouseDown={(e) => e.preventDefault()}
												edge='end'
											>
												{show ? (
													<VisibilityOff className='visibility-icon' />
												) : (
													<Visibility className='visibility-icon' />
												)}
											</IconButton>
										</InputAdornment>
									),
								}}
							/>
							{errors?.password && (
								<h6 className='error'>{errors?.password}</h6>
							)}
						</FormControl>
						<Button
							type='submit'
							loading={loading}
							label='SUBMIT'
							btnClass='reset-btn'
						/>
					</form>
				</CardContent>
				<div className='response-container'>
					{success?.message && (
						<p className='success'>
							<span>
								<CheckCircleOutlineIcon fontSize='small' />
							</span>
							{success?.message}
						</p>
					)}
					{errors?.message && (
						<p className='error'>
							<span>
								<ErrorOutlineIcon fontSize='small' />
							</span>
							{errors?.message}
						</p>
					)}
				</div>
			</Card>
		</div>
	);
};

export default ResetPassword;
