import { Container } from '@mui/material';
import { useSelector } from 'react-redux';
import './memberHome.scss';
import Hero from './components/Hero';
import Bio from './components/Bio';
import Social from './components/Social';
import Featured from './components/Featured';
import Footer from '../../../../components/Footer';

const MemberHome = () => {
	const { featuredContent } = useSelector((state) => state.content);

	return (
		<div id='member-home'>
			<Hero />
			<Bio />
			<Social />
			{featuredContent?.map((item, index) => {
				if ((index + 1) % 2 === 0) {
					return (
						<Featured
							key={item._id}
							sectionClass='alt'
							featuredClass='reverse'
							item={item}
						/>
					);
				} else {
					return <Featured key={item._id} item={item} />;
				}
			})}
			<section id='self-advert'>
				<Container maxWidth='lg'>Self Advertisment</Container>
			</section>
			<section id='bonus'>
				<Container maxWidth='lg'>Bonus</Container>
			</section>
			<Footer />
		</div>
	);
};

export default MemberHome;
